import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import {
  GoogleAssetsContainer,
  googleResourceLimits,
  scrollBarGreen,
} from '../../../../utils/constants/google';
import { MdDeleteOutline } from 'react-icons/md';
import { FloatingButton } from '../../../Buttons';
import { fetchGoogleCallouts } from '../../../../services/googleAds';
import { IoMdAdd, IoMdRemove } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';

interface GoogleTemplatesCalloutsProps {
  submitForm: any;
  templateForm: any;
  setIsDirty: any;
  brandId: string;
}

const GoogleTemplatesCallouts: React.FC<GoogleTemplatesCalloutsProps> = ({
  submitForm,
  templateForm,
  setIsDirty,
  brandId,
}) => {
  const dispatch = useDispatch();
  const [callouts, setCallouts] = useState([]);
  const [googleCallouts, setGoogleCallouts] = useState([]);
  const [target, setTarget] = useState('Campaign Level');
  const maxItems = googleResourceLimits.callouts.items;
  const maxLength = googleResourceLimits.callouts.length;

  const handleTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTarget((event.target as HTMLInputElement).value);
  };

  useEffect(() => {
    console.log(`🚀 brandId`, brandId);
    fetchGoogleCallouts({ brandId: brandId }).then((response) => {
      console.log(`🚀 response`, response);
      console.log(
        `🚀 AUTOMATICALLY_CREATED`,
        response?.filter((c: any) => c.asset.source === 'AUTOMATICALLY_CREATED')
          .length,
      );
      console.log(
        `🚀 ADVERTISER`,
        response?.filter((c: any) => c.asset.source === 'ADVERTISER').length,
      );

      const mapped = response
        .map((c: any) => {
          return {
            calloutText: c.asset.calloutAsset.calloutText,
            resourceName: c.asset.resourceName,
          };
        })
        .sort((a: any, b: any) => {
          //sort alphabetically
          if (a.calloutText < b.calloutText) {
            return -1;
          }
          if (a.calloutText > b.calloutText) {
            return 1;
          }
          return 0;
        });

      const filtered: string[] = [];
      const filteredResources: {
        calloutText: string;
        resourceName: string;
      }[] = [];
      mapped.forEach(
        (callout: { calloutText: string; resourceName: string }) => {
          if (filtered.includes(callout.calloutText)) return;
          filtered.push(callout.calloutText);
          filteredResources.push(callout);
        },
      );

      setGoogleCallouts(filteredResources);
      // setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (templateForm && templateForm.length > 0) {
      setCallouts(templateForm);
    }
  }, [templateForm]);

  useEffect(() => {
    submitForm(callouts);
  }, [callouts]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: `space-between`,
        alignItems: `start`,
      }}
    >
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          width: `100%`,
          borderRadius: 2,
        }}
      >
        <Box sx={{}}>
          <Box>
            <FormControl>
              <FormLabel id="controlled-radio-buttons-group"></FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={target}
                onChange={handleTargetChange}
              >
                <FormControlLabel
                  value="Account Level"
                  control={<Radio />}
                  label="Account level"
                />
                <FormControlLabel
                  value="Campaign Level"
                  control={<Radio />}
                  label="Campaign Level"
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {target === 'Account Level' ? (
            <Box
              sx={{
                backgroundColor: 'white',
                padding: 2,
                marginTop: 2,
                border: `2px solid ${DEFAULT_COLOR_THEME}`,
                borderRadius: 2,
              }}
            >
              {googleCallouts.map((callout, i) => (
                <Box
                  key={`callout-${i}`}
                  sx={{
                    paddingBottom: 1,
                    marginBottom: 1,
                    borderBottom: '1px solid #ccc',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{}}>
                    <Box>
                      <Typography
                        sx={{
                          display: 'inline',
                          fontSize: 10,
                        }}
                      >
                        {i + 1}.
                      </Typography>{' '}
                      {callout.calloutText}
                    </Box>
                    <Box
                      sx={{
                        fontStyle: 'italic',
                        fontSize: 10,
                        color: '#cacaca',
                      }}
                    >
                      {callout.resourceName}
                    </Box>
                  </Box>
                  <Button
                    variant="contained"
                    color={
                      callouts.includes(callout.calloutText)
                        ? 'error'
                        : 'primary'
                    }
                    sx={{
                      margin: 0,
                      paddingX: 2,
                      minWidth: 0,
                      borderRadius: `50%`,
                    }}
                    onClick={() => {
                      setIsDirty(true);
                      if (callouts.includes(callout.calloutText)) {
                        setCallouts(
                          callouts.filter((c) => c !== callout.calloutText),
                        );
                      } else {
                        if (callouts.length >= maxItems) {
                          return dispatch(
                            toggleAlert({
                              toggle: true,
                              type: 'error',
                              message: 'Maximum number of callouts reached',
                            }),
                          );
                        }
                        setCallouts([...callouts, callout.calloutText]);
                      }
                    }}
                  >
                    {callouts.includes(callout.calloutText) ? (
                      <IoMdRemove />
                    ) : (
                      <IoMdAdd />
                    )}
                  </Button>
                </Box>
              ))}
            </Box>
          ) : null}
          {target === 'Campaign Level' ? (
            <Box>
              <TextField
                multiline
                rows={17}
                sx={{
                  backgroundColor: 'white',
                  border: `2px solid ${DEFAULT_COLOR_THEME}`,
                  borderRadius: 2,
                }}
                value={callouts.join('\n')}
                onChange={(e) => {
                  setIsDirty(true);

                  const temp = e.target.value.split('\n');
                  if (temp.length > maxItems) {
                    temp.splice(maxItems, temp.length - maxItems);
                  }
                  setCallouts(temp);
                }}
                placeholder="Enter or paste callouts separated by new lines"
                fullWidth
              />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: `#eeeeee`,
          padding: `1rem 2rem`,
          maxWidth: 500,
          overflowY: 'auto',
          width: `100%`,
          borderRadius: 2,
          display: callouts.length > 0 ? 'block' : 'none',
          ...scrollBarGreen,
        }}
      >
        <Typography
          variant="h6"
          fontWeight={800}
          mb={2}
          color={DEFAULT_COLOR_THEME}
        >
          Callouts [{callouts.length} of {maxItems}]
        </Typography>
        {callouts.map((callout, index) => (
          <Box
            sx={{
              textAlign: 'right',
              marginBottom: '1rem',
              maxWidth: 800,
              position: 'relative',
            }}
          >
            <TextField
              error={callout.length > maxLength || callout === ''}
              sx={{
                backgroundColor: 'white',
                '& input': {
                  paddingRight: `50px`,
                },
              }}
              id={`Callouts-${index}`}
              label={`Callout ${index + 1}`}
              variant="outlined"
              name={`Callout-${index}`}
              fullWidth
              value={callout}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="delete button"
                      onClick={() => {
                        const temp = [...callouts];
                        temp.splice(index, 1);
                        setCallouts(temp);
                        setIsDirty(true);
                      }}
                    >
                      <MdDeleteOutline />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setIsDirty(true);
                const updatedItem = [...callouts];
                updatedItem[index] = e.target.value;
                setCallouts(updatedItem);
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color:
                    callout.length > maxLength || callout === ''
                      ? 'red'
                      : 'black',
                }}
              >
                {`${callout.length}/${maxLength}`}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  color:
                    callout.length > maxLength || callout === ''
                      ? 'red'
                      : 'black',
                }}
              >
                {callout.length > maxLength ? 'Max length reached' : ''}
                {callout === '' ? 'Empty callout' : ''}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GoogleTemplatesCallouts;
