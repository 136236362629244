import { Box, Checkbox, Drawer, Tab, Tooltip, Typography } from '@mui/material';
import GoogleDirectDrawer from './GoogleDirectDrawer';
import { googleReportsColumnDefs } from './GoogleCampaignColumn';

import {
  DataGridPremium,
  DataGridPremiumProps,
  useGridApiContext,
} from '@mui/x-data-grid-premium';

import {
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
  MdOutlinePauseCircleOutline,
  MdOutlinePlayCircle,
  MdOutlineCancel,
  MdPlayCircleOutline,
} from 'react-icons/md';

import { FaCirclePause, FaCirclePlay, FaCircleXmark } from 'react-icons/fa6';

import { RxDash } from 'react-icons/rx';
import { IoIosCloseCircleOutline, IoMdAddCircleOutline } from 'react-icons/io';

import Button, { ButtonProps } from '@mui/material/Button';
import { useRef, useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import { CustomLoadingOverlay } from '../Loading';
import {
  googleEnums,
  googleHelpers,
  scrollBarGreen,
  biddingStrategies,
} from '../../utils/constants/google';
import { toggleAlert } from '../../redux/actions';
import { DEFAULT_COLOR_THEME } from '../../utils';
import { modifyGoogleResourceStatus } from '../../services/googleAds';
import { ro } from '@faker-js/faker';
import GoogleTemplatesPreview from '../Forms/Templatez/Google/GGLTemplatesPreview';
import { TabContext, TabList, TabPanel } from '@mui/lab';

interface termsListType {
  id: number;
  text: string;
  matchtype: string;
  resource_name: string;
  ad_group_resource_name: string;
  status: string;
}

interface GoogleDirectCampaignTableProps {
  refreshToken: string;
  customer: any[];
  ad_groups: any[];
  ad_group_ad: any[];
  keywords: any[];
  bidding_strategies: any[];
  search_terms: any[];
  locations: any[];
  loading: boolean;
  campaignList: string[];
  allowCheckboxes: boolean;
  reload?: any;
  brandId: string;
  termsList: termsListType[];
  setTermsList: Dispatch<SetStateAction<termsListType[]>>;
}

const GoogleDirectCampaignTable: React.FC<GoogleDirectCampaignTableProps> = ({
  refreshToken,
  customer,
  ad_groups,
  ad_group_ad,
  keywords,
  bidding_strategies,
  search_terms,
  locations,
  loading,
  campaignList,
  allowCheckboxes = false,
  reload,
  brandId,
  termsList,
  setTermsList,
}) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState<any[]>([]);
  const [termType, setTermType] = useState<string>('keyword');
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const pinnedRows: any = {
    top: [],
    bottom: [],
  };
  const [termLock, setTermLock] = useState<string | null>(null);
  const [adGroupLock, setAdGroupLock] = useState<string | null>(null);
  const [adGroupName, setAdGroupName] = useState<string | null>(null);
  const [campaignLock, setCampaignLock] = useState<string | null>(null);
  const [prevTermsListLength, setPrevTermsListLength] = useState<number>(0);
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [drawerContent, setDrawerContent] = useState<any>(null);
  const endoftable = useRef<HTMLDivElement>(null);
  const [confirmButton, setConfirmButton] = useState<{
    ENABLE: boolean;
    PAUSE: boolean;
    REMOVE: boolean;
  }>({
    ENABLE: false,
    PAUSE: false,
    REMOVE: false,
  });
  const [savingButton, setSavingButton] = useState<boolean>(false);

  const [tabValue, setTabValue] = useState('1');

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const round = (num: number, places: number) => {
    return +(Math.round(Number(num + 'e' + places)) + 'e-' + places);
  };

  useEffect(() => {
    setConfirmButton({
      ENABLE: false,
      PAUSE: false,
      REMOVE: false,
    });

    setSavingButton(false);
    let rows: any[] = [];

    console.log(`🗒️ ad_group_ad`, ad_group_ad);
    const filtered_ad_groups = ad_groups.filter((c: any) => {
      return campaignList.includes(c.campaign.id.toString());
    });

    // const topRow = {
    //   id: `c-all`,
    //   path: [`c-all-path`],
    //   text: 'CUSTOMER',
    //   campaign: 'All Campaigns',
    //   rowType: 'campaign',
    //   status: '',
    //   actype: '',
    //   Clicks: customer[0].metrics.clicks,
    //   Leads: round(customer[0].metrics.all_conversions, 1),
    //   Costs: googleHelpers.micros(customer[0].metrics.cost_micros),
    //   Impressions: customer[0].metrics.impressions,
    //   CPC: customer[0].metrics.clicks
    //     ? googleHelpers.micros(customer[0].metrics.cost_micros) /
    //       customer[0].metrics.clicks
    //     : 0,
    //   CPM: googleHelpers.micros(
    //     customer[0].metrics.impressions
    //       ? customer[0].metrics.cost_micros / customer[0].metrics.impressions
    //       : 0,
    //   ),
    //   CPL: customer[0].metrics.all_conversions
    //     ? googleHelpers.micros(customer[0].metrics.cost_micros) /
    //       customer[0].metrics.all_conversions
    //     : 0,
    // };
    // rows.push(topRow);
    let expKeys: string[] = [];
    ad_groups
      .filter((c: any) => campaignList.includes(c.campaign.id.toString()))
      .sort((a, b) => a.campaign.status - b.campaign.status)
      .forEach((adgroup: any, ci: number) => {
        if (
          adgroup.campaign.status === 4 ||
          (adgroup.campaign.status === 3 &&
            adgroup.campaign.metrics.cost_micros === 0)
        ) {
          return;
        }
        expKeys.push(`c${ci}`);
        //if (!rows.find((r) => r.id === `c${ci}`)) {
        rows.push({
          id: `c${ci}`,
          path: [`campaign-[${ci}]`],
          text: '',
          rowType: 'campaign',
          defex: true,
          campaign: adgroup.campaign.name,
          resource_name: adgroup.campaign.resource_name,
          bidding_strategy: adgroup.campaign.bidding_strategy_type,
          bidding_strategy_description:
            biddingStrategies.find(
              (bs) =>
                bs.biddingType ===
                googleEnums.biddingStrategyType[
                  adgroup.campaign.bidding_strategy_type
                ],
            )?.description || '',
          bidding_strategy_system_status:
            adgroup.campaign.bidding_strategy_system_status,
          status: googleEnums.campaignStatus[adgroup.campaign.status],
          actype: adgroup.campaign.advertising_channel_type,
          Clicks: adgroup.campaign.metrics
            ? adgroup.campaign.metrics.clicks
            : 0,
          Leads: adgroup.campaign.metrics
            ? round(adgroup.campaign.metrics.all_conversions, 1)
            : 0,
          Costs: adgroup.campaign.metrics
            ? googleHelpers.micros(adgroup.campaign.metrics.cost_micros)
            : 0,
          Impressions: adgroup.campaign.metrics
            ? adgroup.campaign.metrics.impressions
            : 0,
          CPC:
            adgroup.campaign.metrics.clicks > 0
              ? googleHelpers.micros(
                  adgroup.campaign.metrics.cost_micros /
                    adgroup.campaign.metrics.clicks,
                )
              : 0,
          CPM:
            adgroup.campaign.metrics.impressions > 0
              ? googleHelpers.micros(adgroup.campaign.metrics.cost_micros) /
                adgroup.campaign.metrics.impressions
              : 0,
          CPL:
            adgroup.campaign.metrics.all_conversions > 0
              ? googleHelpers.micros(
                  adgroup.campaign.metrics.cost_micros /
                    adgroup.campaign.metrics.all_conversions,
                )
              : 0,
        });
        //}
        adgroup.ad_groups
          .filter(
            (ag: any) =>
              ag.status !== 4 &&
              (ag.status !== 3 || ag.metrics.cost_micros > 0),
          )
          .forEach((adg: any, ai: number) => {
            expKeys.push(`c${ci}-ag${ai}`);
            //if (!rows.find((r) => r.id === `c${ci}-ag${ai}`)) {
            rows.push({
              id: `c${ci}-ag${ai}`,
              path: [`campaign-[${ci}]`, `ad_group-[${ai}]`],
              rowType: 'ad_group',
              defex: true,
              campaign: adgroup.campaign.name,
              status: googleEnums.campaignStatus[adg.status],
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              Clicks: adg.metrics ? adg.metrics.clicks : 0,
              Leads: adg.metrics ? round(adg.metrics.all_conversions, 1) : 0,
              Costs: adg.metrics
                ? googleHelpers.micros(adg.metrics.cost_micros)
                : 0,
              Impressions: adg.metrics ? adg.metrics.impressions : 0,
              CPC:
                adg.metrics.clicks > 0
                  ? googleHelpers.micros(
                      adg.metrics.cost_micros / adg.metrics.clicks,
                    )
                  : 0,
              CPM:
                adg.metrics.impressions > 0
                  ? googleHelpers.micros(
                      adg.metrics.cost_micros / adg.metrics.impressions,
                    )
                  : 0,
              CPL:
                adg.metrics.all_conversions > 0
                  ? googleHelpers.micros(
                      adg.metrics.cost_micros / adg.metrics.all_conversions,
                    )
                  : 0,
            });
            //}
            //if (adgroup.campaign.advertising_channel_type !== `DISPLAY`) {
            const kws = keywords.filter(
              (kw) =>
                kw.ad_group.id === adg.id && !kw.ad_group_criterion.negative,
            );
            const sts = search_terms?.filter((st) => st.ad_group.id === adg.id);
            const ads = ad_group_ad?.filter(
              (ad) => ad.ad_group.id === adg.id && ad.status !== 4,
            );
            //  if (!rows.find((r) => r.id === `c${ci}-ag${ai}-ads`)) {
            rows.push({
              id: `c${ci}-ag${ai}-ads`,
              path: [`campaign-[${ci}]`, `ad_group-[${ai}]`, `ad`],
              campaign: adgroup.campaign.name,
              status: 'ENABLED',
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              term: ads?.length > 0 ? `Ads [${ads.length}]` : `No Ads`,
              Clicks: '',
              Leads: '',
              Costs: '',
            });
            // }
            ads?.forEach((ad: any, aind: number) => {
              //  if (!rows.find((r) => r.id === `c${ci}-ag${ai}-ad${ai}`)) {
              rows.push({
                id: `c${ci}-ag${ai}-ad${aind}`,
                path: [
                  `campaign-[${ci}]`,
                  `ad_group-[${ai}]`,
                  `ad`,
                  `ad-[${aind}]`,
                ],
                rowType: 'ad',
                campaign: adgroup.campaign.name,
                status: googleEnums.campaignStatus[ad.ad_group_ad.status],
                actype: adgroup.campaign.advertising_channel_type,
                ad_group: adg.name,
                ad_group_resource_name: adg.resource_name,
                resource_name: ad.ad_group_ad.resource_name,
                url: ad.ad_group_ad?.ad?.final_urls,
                raw:
                  ad.ad_group_ad?.ad?.responsive_display_ad ||
                  ad.ad_group_ad?.ad?.responsive_search_ad,
                headlines:
                  (
                    ad.ad_group_ad?.ad?.responsive_display_ad ||
                    ad.ad_group_ad?.ad?.responsive_search_ad
                  )?.headlines
                    .map((h: any) => h.text)
                    .splice(
                      0,
                      (
                        ad.ad_group_ad?.ad?.responsive_display_ad ||
                        ad.ad_group_ad?.ad?.responsive_search_ad
                      ).headlines.length > 2
                        ? 2
                        : 0,
                    )
                    .join(', ') || '',
                adGroupId: adg.id,
                text: ad.resource_name,
                term: `Ads`,
                Clicks: ad.metrics ? ad.metrics.clicks : 0,
                Leads: ad.metrics ? round(ad.metrics.all_conversions, 1) : 0,
                Costs: ad.metrics
                  ? googleHelpers.micros(ad.metrics.cost_micros)
                  : 0,
                Impressions: ad.metrics ? ad.metrics.impressions : 0,
                CPC:
                  ad.metrics.clicks > 0
                    ? googleHelpers.micros(
                        ad.metrics.cost_micros / ad.metrics.clicks,
                      )
                    : 0,
                CPM:
                  ad.metrics.impressions > 0
                    ? googleHelpers.micros(
                        ad.metrics.cost_micros / ad.metrics.impressions,
                      )
                    : 0,
                CPL:
                  ad.metrics.all_conversions > 0
                    ? googleHelpers.micros(
                        ad.metrics.cost_micros / ad.metrics.all_conversions,
                      )
                    : 0,
              });
              //  }
            });
            // if (!rows.find((r) => r.id === `c${ci}-ag${ai}-kwx`)) {
            rows.push({
              id: `c${ci}-ag${ai}-kwx`,
              path: [`campaign-[${ci}]`, `ad_group-[${ai}]`, `Keywords`],
              campaign: adgroup.campaign.name,
              status: googleEnums.campaignStatus[adg.status],
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              term: kws.length > 0 ? `Keywords [${kws.length}]` : `No Keywords`,
              Clicks: '',
              Leads: '',
              Costs: '',
            });
            // }
            kws.forEach((kw: any, ki: number) => {
              if (!rows.find((r) => r.id === `c${ci}-ag${ai}-kw${ki}`)) {
                rows.push({
                  id: `c${ci}-ag${ai}-kw${ki}`,
                  path: [
                    `campaign-[${ci}]`,
                    `ad_group-[${ai}]`,
                    `Keywords`,
                    `keyword_view-[${ki}]`,
                  ],
                  rowType: 'keyword',
                  campaign: adgroup.campaign.name,
                  status: kw.status,
                  actype: adgroup.campaign.advertising_channel_type,
                  ad_group: adg.name,
                  ad_group_resource_name: adg.resource_name,
                  adGroupId: adg.id,
                  text: kw.ad_group_criterion.keyword.text,
                  positionEstimates:
                    kw.ad_group_criterion.position_estimates ||
                    'No Position Estimates',
                  isNegative: kw.ad_group_criterion.negative,
                  matchtype:
                    googleEnums.keywords[
                      kw.ad_group_criterion.keyword.match_type
                    ],
                  resource_name: kw.ad_group_criterion.resource_name,
                  term: `Keywords`,
                  Clicks: kw.metrics ? kw.metrics.clicks : 0,
                  Leads: kw.metrics ? round(kw.metrics.all_conversions, 1) : 0,
                  Costs: kw.metrics.cost_micros
                    ? googleHelpers.micros(kw.metrics.cost_micros)
                    : 0,
                  Impressions: kw.metrics ? kw.metrics.impressions : 0,
                  CPC:
                    kw.metrics.clicks > 0
                      ? googleHelpers.micros(
                          kw.metrics.cost_micros / kw.metrics.clicks,
                        )
                      : 0,
                  CPM:
                    kw.metrics.impressions > 0
                      ? googleHelpers.micros(
                          kw.metrics.cost_micros / kw.metrics.impressions,
                        )
                      : 0,
                  CPL:
                    kw.metrics.all_conversions > 0
                      ? googleHelpers.micros(
                          kw.metrics.cost_micros / kw.metrics.all_conversions,
                        )
                      : 0,
                });
              }
            });
            //if (!rows.find((r) => r.id === `c${ci}-ag${ai}-stx`)) {
            rows.push({
              id: `c${ci}-ag${ai}-stx`,
              path: [`campaign-[${ci}]`, `ad_group-[${ai}]`, `Search Term`],
              campaign: adgroup.campaign.name,
              status: googleEnums.campaignStatus[adg.status],
              actype: adgroup.campaign.advertising_channel_type,
              ad_group: adg.name,
              ad_group_resource_name: adg.resource_name,
              adGroupId: adg.id,
              text: '',
              term:
                sts.length > 0
                  ? `Search Terms [${sts.length}]`
                  : `No Search Terms`,
              Clicks: '',
              Leads: '',
              Costs: '',
            });
            //}
            sts
              .filter((st) => st.status !== 4 && st.metrics.impressions > 0)
              .forEach((st: any, si: number) => {
                //if (!rows.find((r) => r.id === `c${ci}-ag${ai}-st${si}`)) {
                rows.push({
                  id: `c${ci}-ag${ai}-st${si}`,
                  path: [
                    `campaign-[${ci}]`,
                    `ad_group-[${ai}]`,
                    `Search Term`,
                    `search_term_view-[${si}]`,
                  ],
                  rowType: 'search_term',
                  campaign: adgroup.campaign.name,
                  status: st.status,
                  actype: adgroup.campaign.advertising_channel_type,
                  ad_group: adg.name,
                  ad_group_resource_name: adg.resource_name,
                  adGroupId: adg.id,
                  text: st.search_term_view.search_term,
                  term: 'Search Term',
                  matchtype:
                    googleEnums.keywords[st.segments.keyword.info.match_type],
                  matchingKeyword: st.segments.keyword.info.text,
                  Clicks: st.metrics ? st.metrics.clicks : 0,
                  Leads: st.metrics ? round(st.metrics.all_conversions, 1) : 0,
                  Impressions: st.metrics ? st.metrics.impressions : 0,
                  Costs: st.metrics.cost_micros
                    ? googleHelpers.micros(st.metrics.cost_micros)
                    : 0,
                  CPC:
                    st.metrics.clicks > 0
                      ? googleHelpers.micros(
                          st.metrics.cost_micros / st.metrics.clicks,
                        )
                      : 0,
                  CPM:
                    st.metrics.impressions > 0
                      ? googleHelpers.micros(
                          st.metrics.cost_micros / st.metrics.impressions,
                        )
                      : 0,
                  CPL:
                    st.metrics.all_conversions > 0
                      ? googleHelpers.micros(
                          st.metrics.cost_micros / st.metrics.all_conversions,
                        )
                      : 0,
                });
                //}
              });
            //}
          });
      });
    let lc = {
      id: `locations-all`,
      path: [`locations`],
      text: '',
      rowType: 'location',
      campaign: 'Locations',
      defex: true,
      status: '',
      actype: '',
      Clicks: '',
      Leads: '',
      Costs: '',
      Impressions: '',
      CPC: '',
      CPM: '',
      CPL: '',
    };

    if (rows.length > 0) {
      // pinnedRows.top.push(topRow as any);
      rows.push({ ...lc });
      locations.forEach((location: any, li: number) => {
        let lc = {
          id: `locations-${li}`,
          path: [`locations`, `locations-[${li}]`],
          campaign: location.location_name,
          location: location.location_name,
          rowType: 'location-child',
          Clicks: location.metrics ? location.metrics.clicks : 0,
          Leads: location.metrics
            ? round(location.metrics.all_conversions, 1)
            : 0,
          Costs: location.metrics
            ? googleHelpers.micros(location.metrics.cost_micros)
            : 0,
          Impressions: location.metrics ? location.metrics.impressions : 0,
          CPC:
            location.metrics.clicks > 0
              ? googleHelpers.micros(
                  location.metrics.cost_micros / location.metrics.clicks,
                )
              : 0,
          CPM:
            location.metrics.impressions > 0
              ? googleHelpers.micros(
                  location.metrics.cost_micros / location.metrics.impressions,
                )
              : 0,
          CPL:
            location.metrics.all_conversions > 0
              ? googleHelpers.micros(
                  location.metrics.cost_micros /
                    location.metrics.all_conversions,
                )
              : 0,
        };
        rows.push({ ...lc });
      });
    }
    console.log(`🗒️ rows`, rows);
    setExpandedKeys(expKeys);
    setRows(rows);
  }, [ad_groups]);

  useEffect(() => {
    setPrevTermsListLength(termsList.length);

    if (termsList.length === 1 && prevTermsListLength === 0) {
      endoftable.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [termsList]);

  let tempTermList: any[] = [...termsList];

  const removeFromTermList = (row: any) => {
    let tempremove = [...termsList];
    tempremove = tempremove.filter((t) => t.id !== row.id);
    if (tempremove.length === 0) {
      setAdGroupLock(null);
      setTermLock(null);
      setCampaignLock(null);
      setAdGroupName(null);
    }
    setTermsList(tempremove);
  };

  const removeAllFromTermList = () => {
    tempTermList = [];
    setAdGroupLock(null);
    setTermLock(null);
    setCampaignLock(null);
    setAdGroupName(null);
    setTermsList(tempTermList);
  };

  const addToTermList = async (row: any) => {
    let tempadd = [...termsList];
    tempadd = [...tempadd, row];
    tempTermList = tempadd;
    setTermsList([...termsList.filter((t) => t.id !== row.id), row]);
  };

  const CustomGridTreeDataGroupingCell = (props: any) => {
    const { id, row, rowNode } = props;
    const dgApiRef = useGridApiContext();
    const iconsProps = {
      size: `24px`,
    };

    const handleClick: ButtonProps['onClick'] = (event) => {
      event.stopPropagation();
      if (rowNode.type !== 'group') {
        return;
      }

      rowNode.childrenExpanded = !rowNode.childrenExpanded;

      dgApiRef.current.setRowChildrenExpansion(id, rowNode.childrenExpanded);
      if (rowNode.childrenExpanded) {
        let expandedKs = [...expandedKeys, rowNode.id];
        setExpandedKeys(expandedKs);
      } else {
        let expandedKs = expandedKeys.filter((k) => k !== rowNode.id);
        setExpandedKeys(expandedKs);
      }
    };

    const setDContent = (row: any) => {
      setDrawerContent({
        ...row,
        preview: {
          url: row.url?.length > 0 ? row.url[0] : '',
          headlines: row?.raw?.headlines.map((h: any) => h.text) || [],
          descriptions: row?.raw?.descriptions.map((d: any) => d.text) || [],
        },
      });
    };

    const handleSideBar: ButtonProps['onClick'] = (event) => {
      event.stopPropagation();
      console.log(`🗒️ row`, row);
      setDContent(row);
      // if (rowNode.depth === 0) {
      //   setDrawerOpen(true);
      // } else handleClick(event);

      if (
        row.rowType === 'campaign' ||
        row.rowType === 'ad_group' ||
        row.rowType === 'ad'
      ) {
        setDrawerOpen(true);
      } else handleClick(event);
    };

    const handleCheckBoxClick: ButtonProps['onClick'] = () => {
      if (row.term === 'Ads') {
        console.log(`🗒️ row`, row);
        setDContent(row);
        return setDrawerOpen(true);
      }

      if (!allowCheckboxes) {
        return;
      }

      const { adGroupId, term, campaign, ad_group } = row;

      if (
        (adGroupLock && adGroupLock !== row.adGroupId) ||
        (termLock && termLock !== row.term)
      ) {
        return dispatch(
          toggleAlert({
            toggle: true,
            type: 'error',
            message: `Please select ${termLock} from the same ad group`,
          }),
        );
      }

      if (termsList.map((t) => t.id).includes(row.id)) {
        return removeFromTermList(row);
      }

      setAdGroupLock(adGroupId);
      setAdGroupName(ad_group);
      setTermLock(term);
      setCampaignLock(campaign);

      setTermType(term);
      addToTermList(row);
    };

    row.tooltip = '';
    row.title =
      row.rowType === `ad`
        ? row.headlines
        : row.text ||
          row.ad_group ||
          `${row.campaign} ${row.actype ? ` - ` + row.actype : ``}`;
    if (rowNode.type === `group` || rowNode.depth < 3) {
      row.tooltip = `${row.campaign}${row.actype ? ` - ` + row.actype : ``}`;

      if (rowNode.depth === 0) {
        row.title = `${row.campaign} ${row.actype ? ` - ` + row.actype : ``}`;
      }
      if (rowNode.depth === 1) {
        row.title = row.ad_group || row.location;
      }
      if (rowNode.depth === 2) {
        row.title = row.term;
      }
    }

    if (rowNode.type === `leaf`) {
      row.tooltip = `${row.title} - ${row.status} ${
        row.matchingKeyword ? ` (${row.matchingKeyword})` : ''
      }`;
    }

    return (
      <Box
        sx={{
          ml: rowNode.depth !== 3 ? rowNode.depth * 2 : 4,
        }}
      >
        {rowNode.type === `group` || rowNode.depth < 3 ? (
          <Box sx={{ display: `flex` }}>
            <Button
              onClick={handleClick}
              tabIndex={-1}
              size="small"
              sx={{
                display: `flex`,
                flexShrink: 0,
                gap: `1em`,
                height: `2.7em`,
                justifyContent: `flex-start`,
                alignItems: `center`,
                color: `#000000`,
                minWidth: `30px`,
                margin: 0,
                padding: 0,
              }}
            >
              {rowNode.depth === 0 || rowNode.depth === 2 ? (
                row.actype === `PERFORMANCE MAX` || row.id === 'c-all' ? (
                  <RxDash {...iconsProps} />
                ) : //@ts-ignore
                rowNode.childrenExpanded ? (
                  <MdOutlineArrowDropUp size={24} />
                ) : (
                  <MdOutlineArrowDropDown size={24} />
                )
              ) : null}
              {rowNode.depth === 1 ? (
                !row.rowType?.includes(`location`) ? (
                  //@ts-ignore
                  rowNode.childrenExpanded ? (
                    <MdOutlineArrowDropUp {...iconsProps} />
                  ) : (
                    <MdOutlineArrowDropDown {...iconsProps} />
                  )
                ) : (
                  <RxDash {...iconsProps} />
                )
              ) : null}
            </Button>
            <Tooltip
              title={`${row.tooltip}${
                row.status === 'PAUSED' ? ' - PAUSED' : ''
              }`}
              disableInteractive
            >
              <Button
                onClick={handleSideBar}
                tabIndex={-1}
                size="small"
                sx={{
                  display: `flex`,
                  gap: `1em`,
                  height: `2.7em`,
                  width: `100%`,
                  justifyContent: `flex-start`,
                  alignItems: `center`,
                  color: `#000000`,
                }}
              >
                {rowNode.depth === 0 ? (
                  <Box
                    display={`flex`}
                    justifyContent={`start`}
                    alignItems={`center`}
                    gap={1}
                  >
                    {row.title}
                    {row.status === 'PAUSED' ? (
                      <MdOutlinePauseCircleOutline {...iconsProps} />
                    ) : (
                      <></>
                    )}
                    <Typography
                      sx={{
                        fontSize: `0.6rem`,
                        display:
                          row.id === `c-all` || row.rowType === 'location'
                            ? `none`
                            : `block`,
                        backgroundColor: `#f0f0f0`,
                        padding: `0.2em 0.4em`,
                        borderRadius: `2em`,
                      }}
                    >
                      {row.actype}
                    </Typography>
                  </Box>
                ) : rowNode.depth === 1 ? (
                  <>
                    {row.title}

                    {row.status === 'PAUSED' ? (
                      <MdOutlinePauseCircleOutline {...iconsProps} />
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <>
                    {row.title}
                    {row.status === 'PAUSED' ? (
                      <MdOutlinePauseCircleOutline {...iconsProps} />
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Button>
            </Tooltip>
          </Box>
        ) : (
          <Tooltip
            title={
              row.rowType === 'search_term' ? (
                <Box>
                  <Typography fontSize={`0.8rem`}>
                    Search Term: {row.title}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Status: {row.status}
                  </Typography>

                  <hr />
                  <Typography fontSize={`0.8rem`}>
                    Matching Keyword: {row.matchingKeyword}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Keyword Match Type: {row.matchtype}
                  </Typography>
                </Box>
              ) : row.rowType === 'keyword' ? (
                <Box>
                  <Typography fontSize={`0.8rem`}>
                    Keyword: {row.title}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Status: {row.status}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Position Estimates: {row.positionEstimates}
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography fontSize={`0.8rem`}>
                    Status: {row.status}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Campaign: {row.campaign}
                  </Typography>
                  <Typography fontSize={`0.8rem`}>
                    Ad Group: {row.ad_group}
                  </Typography>
                </Box>
              )
            }
            disableInteractive
          >
            <Button
              sx={{
                width: `100%`,
                display: `flex`,
                justifyContent: `start`,
                alignItems: `center`,
              }}
              type="button"
              onClick={handleCheckBoxClick}
            >
              <Checkbox
                id={row.id}
                checked={termsList.find((t) => t.id === row.id) ? true : false}
                readOnly
                sx={{
                  padding: `0`,
                  margin: `0 10px 0 0`,
                  display:
                    row.term === 'Ads'
                      ? 'none'
                      : allowCheckboxes
                      ? `block`
                      : `none`,
                }}
              />
              <Typography
                sx={{
                  fontSize: `0.8rem`,
                  pointerEvents: `none`,
                  color: `#000000`,
                  textTransform: `none`,
                  display: `flex`,
                  justifyContent: `start`,
                  alignItems: `center`,
                  gap: `0.2em`,
                }}
              >
                {row.title}{' '}
                {row.status === 'PAUSED' ? (
                  <MdOutlinePauseCircleOutline {...iconsProps} />
                ) : null}
                {row.status === 'REMOVED' || row.status === 'EXCLUDED' ? (
                  <IoIosCloseCircleOutline {...iconsProps} />
                ) : null}
                {row.status === 'ADDED' ? (
                  <IoMdAddCircleOutline {...iconsProps} />
                ) : null}
              </Typography>
            </Button>
          </Tooltip>
        )}
      </Box>
    );
  };

  const customSortComparator: any = (
    v1: any,
    v2: any,
    cellParams1: any,
    cellParams2: any,
  ) => {
    if (
      (cellParams1.rowNode.type === 'group' &&
        cellParams1.id.toString().includes('location')) ||
      (cellParams2.rowNode.type === 'group' &&
        cellParams2.id.toString().includes('location'))
    ) {
      return 0;
    }

    if (v1 < v2) {
      return -1;
    } else if (v1 > v2) {
      return 1;
    } else {
      return 0;
    }
  };

  const groupingColDef: DataGridPremiumProps['groupingColDef'] = {
    headerName: 'Campaigns',
    sortable: true,
    minWidth: 450,
    flex: 1,
    headerClassName: 'bg-green',
    valueGetter: (_, row) => {
      return (
        row.text ||
        row.ad_group ||
        `${row.campaign} ${row.actype ? ` - ` + row.actype : ``}`
      );
    },
    sortComparator: customSortComparator,
    renderCell: (params: any) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  const campaignDrawerLabels: { [key: string]: string } = {
    campaign: 'Campaign',
    actype: 'Advertising Channel Type',
    status: 'Status',

    bidding_strategy: 'Bidding Strategy',
    bidding_strategy_description: 'Bidding Strategy Description',
    bidding_strategy_system_status: 'Bidding Strategy System Status',

    Clicks: 'Clicks',
    Leads: 'Leads',
    Costs: 'Costs',
    Impressions: 'Impressions',
    CPC: 'CPC',
    CPM: 'CPM',
    CPL: 'CPL',
  };

  const campaignDrawerLabelsValues: { [key: string]: any } = {
    bidding_strategy: (value: number) =>
      googleEnums.biddingStrategyType[value] || value,
    bidding_strategy_system_status: (value: number) =>
      googleEnums.biddingStrategySystemStatus[value] || value,
    Costs: (value: number) => (isNaN(value) ? '' : `$${value.toFixed(2)}`),
    Impressions: (value: number) => value.toFixed(0),
    CPC: (value: number) => (isNaN(value) ? '' : `$${value.toFixed(2)}`),
    CPM: (value: number) => (isNaN(value) ? '' : `$${value.toFixed(2)}`),
    CPL: (value: number) => (isNaN(value) ? '' : `$${value.toFixed(2)}`),
  };

  const PreviewDrawer = (
    <Box
      sx={{
        width: 440,
        ...scrollBarGreen,
        minHeight: '100vh',
        overflowY: 'auto',
      }}
      role="presentation"
      onClick={() => {
        // setOpen(false);
      }}
    >
      {/* temporary bidding strat location */}

      <Box>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleTabChange}
              aria-label="lab API tabs example"
              sx={{
                backgroundColor: DEFAULT_COLOR_THEME,
                '.MuiTab-root': {
                  top: 5,
                  color: 'white',
                },
                '.Mui-selected': {
                  color: `${DEFAULT_COLOR_THEME} !important`,
                  backgroundColor: 'white',
                  top: 5,
                  borderRadius: 2,
                },
                width: '100%',
                paddingX: 1,
              }}
            >
              <Tab label="Details" value="1" />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            sx={{
              backgroundColor: '#d9d9d933',
              minHeight: `calc(100vh - 125px )`,
              maxHeight: `calc(100vh - 125px )`,
              padding: 0,
              overflowY: 'auto',
              overflowX: 'hidden',
              '&::-webkit-scrollbar': {
                width: '10px',
              },
              '&::-webkit-scrollbar-track': {
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              },

              '&::-webkit-scrollbar-thumb': {
                background: DEFAULT_COLOR_THEME,
                borderRadius: '18px',

                '&:hover': {
                  background: DEFAULT_COLOR_THEME,
                },
              },
            }}
          >
            {drawerContent ? (
              <Box sx={{ padding: 2 }}>
                <Box
                  sx={{
                    color: DEFAULT_COLOR_THEME,
                    fontWeight: 'bold',
                    paddingY: 2,
                    borderBottom: '1px solid #f0f0f0',
                    backgroundColor: '#f5f5f5',
                  }}
                >
                  {drawerContent.rowType === 'campaign'
                    ? 'Campaign'
                    : drawerContent.rowType === 'ad_group'
                    ? 'Ad Group'
                    : 'Ad'}{' '}
                  Details
                </Box>
                <Box height={`100%`} sx={{}}>
                  {drawerContent.rowType === 'ad' ? (
                    <Box>
                      <GoogleTemplatesPreview
                        category={drawerContent?.actype?.toLowerCase() || ''}
                        headlines={drawerContent?.preview?.headlines || []}
                        descriptions={
                          drawerContent?.preview?.descriptions || []
                        }
                        sitelinks={[]}
                        url={drawerContent?.preview?.url || ''}
                        showToolbar={false}
                        center={true}
                        images={[]}
                        business_logo={''}
                        business_name={''}
                      />
                    </Box>
                  ) : null}
                  {Object.keys(campaignDrawerLabels).map(
                    (key: string, ki: number) =>
                      campaignDrawerLabels[key] && drawerContent[key] ? (
                        <Box key={`key-${ki}`} sx={{ padding: 0.5 }}>
                          <Typography color={DEFAULT_COLOR_THEME}>
                            {campaignDrawerLabels[key] || key}
                          </Typography>
                          <Typography>
                            {campaignDrawerLabelsValues[key]
                              ? campaignDrawerLabelsValues[key](
                                  drawerContent[key],
                                )
                              : drawerContent[key]}
                          </Typography>
                        </Box>
                      ) : null,
                  )}
                </Box>
              </Box>
            ) : null}
          </TabPanel>
          <TabPanel value="2">Item Two</TabPanel>
          <TabPanel value="3">Item Three</TabPanel>
        </TabContext>

        <Box
          sx={{
            position: `sticky`,
            padding: 2,
            bottom: 0,
            left: 0,
            display: `flex`,
            justifyContent: `space-between`,
            alignItems: `center`,
            width: `100%`,
            backgroundColor: '#f5f5f5',
          }}
        >
          <Button
            variant="contained"
            disabled={drawerContent?.status === 'ENABLED' || savingButton}
            color={confirmButton['ENABLE'] ? 'error' : 'primary'}
            onClick={() => {
              setSavingButton(true);
              modifyGoogleResourceStatus({
                brandId: brandId,
                resource_names: [drawerContent.resource_name],
                type: drawerContent.rowType,
                status: 'ENABLED',
              }).then(() => {
                setDrawerContent({
                  ...drawerContent,
                  status: 'ENABLED',
                });
                setRows(
                  rows.map((r) => {
                    if (r.id === drawerContent.id) {
                      return {
                        ...r,
                        status: 'ENABLED',
                      };
                    }
                    return r;
                  }),
                );
                setConfirmButton({
                  ENABLE: false,
                  PAUSE: false,
                  REMOVE: false,
                });
                setSavingButton(false);
                dispatch(
                  toggleAlert({
                    toggle: true,
                    type: 'success',
                    message: `${
                      drawerContent.rowType === 'campaign'
                        ? 'Campaign'
                        : drawerContent.rowType === 'ad_group'
                        ? 'Ad Group'
                        : 'Ad'
                    } Enabled`,
                  }),
                );
              });
            }}
          >
            <MdOutlinePlayCircle
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
            {savingButton
              ? 'Saving'
              : confirmButton['ENABLE']
              ? 'Confirm'
              : 'Enable'}
          </Button>

          <Button
            variant="contained"
            color={confirmButton['PAUSE'] ? 'error' : 'primary'}
            disabled={drawerContent?.status === 'PAUSED' || savingButton}
            onClick={() => {
              if (confirmButton['PAUSE']) {
                setSavingButton(true);
                modifyGoogleResourceStatus({
                  brandId: brandId,
                  resource_names: [drawerContent.resource_name],
                  type: drawerContent.rowType,
                  status: 'PAUSED',
                }).then(() => {
                  setDrawerContent({
                    ...drawerContent,
                    status: 'PAUSED',
                  });
                  setRows(
                    rows.map((r) => {
                      if (r.id === drawerContent.id) {
                        return {
                          ...r,
                          status: 'PAUSED',
                        };
                      }
                      return r;
                    }),
                  );
                  setConfirmButton({
                    ENABLE: false,
                    PAUSE: false,
                    REMOVE: false,
                  });
                  setSavingButton(false);
                  dispatch(
                    toggleAlert({
                      toggle: true,
                      type: 'success',
                      message: `${
                        drawerContent.rowType === 'campaign'
                          ? 'Campaign'
                          : drawerContent.rowType === 'ad_group'
                          ? 'Ad Group'
                          : 'Ad'
                      } Paused`,
                    }),
                  );
                });
              } else {
                setConfirmButton({
                  ENABLE: false,
                  PAUSE: true,
                  REMOVE: false,
                });
              }
            }}
          >
            <MdOutlinePauseCircleOutline
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
            />
            {savingButton
              ? 'Saving'
              : confirmButton['PAUSE']
              ? 'Confirm'
              : 'Pause'}
          </Button>

          <Button
            variant="contained"
            color={confirmButton['REMOVE'] ? 'error' : 'primary'}
            disabled={drawerContent?.status === 'REMOVED' || savingButton}
            onClick={() => {
              if (confirmButton['REMOVE']) {
                setSavingButton(true);
                modifyGoogleResourceStatus({
                  brandId: brandId,
                  resource_names: [drawerContent.resource_name],
                  type: drawerContent.rowType,
                  status: 'REMOVED',
                }).then(() => {
                  setDrawerContent({
                    ...drawerContent,
                    status: 'REMOVED',
                  });
                  setRows(
                    rows.map((r) => {
                      if (r.id === drawerContent.id) {
                        return {
                          ...r,
                          status: 'REMOVED',
                        };
                      }
                      return r;
                    }),
                  );
                  setConfirmButton({
                    ENABLE: false,
                    PAUSE: false,
                    REMOVE: false,
                  });
                  setSavingButton(false);
                  dispatch(
                    toggleAlert({
                      toggle: true,
                      type: 'success',
                      message: `${
                        drawerContent.rowType === 'campaign'
                          ? 'Campaign'
                          : drawerContent.rowType === 'ad_group'
                          ? 'Ad Group'
                          : 'Ad'
                      } Removed`,
                    }),
                  );
                });
              } else {
                setConfirmButton({
                  ENABLE: false,
                  PAUSE: false,
                  REMOVE: true,
                });
              }
            }}
          >
            <MdOutlineCancel
              style={{
                fontSize: 20,
                marginRight: 5,
              }}
              color={confirmButton['REMOVE'] ? 'error' : 'primary'}
              onClick={() => {
                if (confirmButton['REMOVE']) {
                  // modifyGoogleResourceStatus({
                  //   brandId: brandId,
                  //   resource_names: [drawerContent.resource_name],
                  //   type: drawerContent.rowType,
                  //   status: 'REMOVED',
                  // }).then(() => {
                  //   setDrawerContent({
                  //     ...drawerContent,
                  //     status: 'REMOVED',
                  //   });
                  //   setRows(
                  //     rows.map((r) => {
                  //       if (r.id === drawerContent.id) {
                  //         return {
                  //           ...r,
                  //           status: 'REMOVED',
                  //         };
                  //       }
                  //       return r;
                  //     }),
                  //   );

                  // });

                  setConfirmButton({
                    ENABLE: false,
                    PAUSE: false,
                    REMOVE: false,
                  });
                } else {
                  setConfirmButton({
                    ENABLE: false,
                    PAUSE: false,
                    REMOVE: true,
                  });
                }
              }}
            />
            {savingButton
              ? 'Saving'
              : confirmButton['REMOVE']
              ? 'Confirm'
              : 'Remove'}
          </Button>
        </Box>
        <Box sx={{ display: `none` }}>
          <Box marginTop={4} paddingX={2} color={DEFAULT_COLOR_THEME}>
            Other Bidding Strategies:
          </Box>
          {bidding_strategies?.map((bs: any, bi: number) => (
            <Box
              key={`bid-${bi}`}
              sx={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `start`,
                alignItems: `start`,
                gap: `1em`,
                padding: `1em`,
                borderBottom: `1px solid #f0f0f0`,
                '&:last-child': {
                  borderBottom: `none`,
                },
              }}
            >
              <Box>
                <Typography fontWeight={`bold`}>
                  Name: {bs.bidding_strategy?.name}
                </Typography>
                <Typography>ID: {bs.bidding_strategy?.id}</Typography>
                <Typography>
                  Type:{' '}
                  {googleEnums.biddingStrategyType[bs.bidding_strategy?.type]}
                </Typography>
                <Typography>
                  Description:{' '}
                  {biddingStrategies.find(
                    (b) =>
                      b.biddingType ===
                      googleEnums.biddingStrategyType[
                        bs.bidding_strategy?.type
                      ],
                  )?.description || ''}
                </Typography>
              </Box>
              <Box>
                <Typography>Average CPC: {bs.metrics?.average_cpc}</Typography>
                <Typography>Clicks: {bs.metrics?.clicks}</Typography>
                <Typography>Conversions: {bs.metrics?.conversions}</Typography>
                <Typography>
                  Cost: ${googleHelpers.micros(bs.metrics?.cost_micros)}
                </Typography>
                <Typography>
                  Cost Per Conversion: $
                  {googleHelpers.micros(bs.metrics?.cost_per_conversion)}
                </Typography>
                <Typography>Impressions: {bs.metrics?.impressions}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* temporary bidding strat location */}
    </Box>
  );

  return (
    <>
      <Box
        sx={{
          height: '100vh',
          minHeight: '200px',
          maxHeight: '500px',
          width: '90%',
          marginX: 'auto',
          '& .bg-green': {
            backgroundColor: '#096f4d',
            color: '#ffffff',
          },

          '& .campaign': {
            backgroundColor: '#B6C61A',
            '&:hover': {
              backgroundColor: 'rgba(182, 198, 26, 0.9) !important',
            },
          },

          '& .ad_group': {
            backgroundColor: '#B9D870',
            '&:hover': {
              backgroundColor: 'rgba(185, 216, 112, 0.9) !important',
            },
          },

          paddingBottom: '200px',
        }}
      >
        <Box
          sx={{
            height: 550,
            margin: `0 -65px`,
          }}
        >
          <DataGridPremium
            pinnedRows={pinnedRows}
            treeData
            disableRowSelectionOnClick
            getRowId={(row) => row.id}
            getTreeDataPath={(row) => row.path}
            rows={[...rows]}
            columns={googleReportsColumnDefs}
            density="compact"
            hideFooterSelectedRowCount
            slots={{ loadingOverlay: CustomLoadingOverlay }}
            groupingColDef={groupingColDef}
            hideFooter
            initialState={{
              sorting: { sortModel: [{ field: 'Costs', sort: 'desc' }] },
            }}
            isGroupExpandedByDefault={(group) => {
              return expandedKeys.includes(group.id as string);
            }}
            defaultGroupingExpansionDepth={2}
            getRowClassName={(params) => {
              return params.row.rowType === 'campaign' ||
                params.row.rowType === 'location'
                ? 'campaign'
                : params.row.rowType === 'ad_group'
                ? 'ad_group'
                : '';
            }}
            sx={{
              'MuiDataGrid-virtualScrollerContent': {
                '&::-webkit-scrollbar': {
                  width: '10px',
                },
                '&::-webkit-scrollbar-track': {
                  // background: "#F1F0F0",
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: `#096f4d`,
                  borderRadius: '18px',

                  '&:hover': {
                    background: `#096f4d`,
                  },
                },
              },
            }}
          />
        </Box>

        <Box
          ref={endoftable}
          sx={{
            height: `30vh`,
            maxHeight: `250px`,
            display: termsList
              ? termsList.length === 0
                ? `none`
                : `block`
              : `none`,
          }}
        />
      </Box>

      <GoogleDirectDrawer
        brandId={brandId}
        termType={termType}
        termsList={termsList}
        campaignLock={campaignLock}
        adGroupLock={adGroupLock}
        adGroupName={adGroupName}
        uncheckAllTerms={removeAllFromTermList}
        removeFromTermList={removeFromTermList}
        updateRows={(rs: any[], status: string) => {
          removeAllFromTermList();
          // reload();
          setRows(
            rows.map((r) => {
              if (rs.includes(r.id)) {
                return {
                  ...r,
                  status: status,
                };
              }
              return r;
            }),
          );
        }}
      />

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      >
        {PreviewDrawer}
      </Drawer>
    </>
  );
};

export default GoogleDirectCampaignTable;
