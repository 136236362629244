import {
  Autocomplete,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  clientBaseUrl,
  hubSpotClientId,
  hubSpotOauthBaseUrl,
  hubSpotScope,
} from '../../../../../services/api';
import { PrimaryButton } from '../../../../Buttons';
import { FaHubspot } from 'react-icons/fa';
import { CrmSetting } from '../../../../../types/ICrm';
import { Brand } from '../../../../../types';
import PopupModal from '../../../../Modal';
import HubSpotPipelinesForm from './HubSpotPipelinesForm';
import { HubSpotLifecycleStageOption } from '../../../../../types/IHubSpot';
import {
  getHubSpotContactLifecycle,
  syncHubSpotLeads,
} from '../../../../../services/hubspot';
import { ScrollStyle } from '../../../../../utils';
import { orderArrayOfObject } from '../../../../../utils/arrayFormatter';
import { MdSync } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { errorMessageParser } from '../../../../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../../../../redux/actions';

interface HubSpotCrmFormProps {
  formValues: CrmSetting;
  setFormValues: Dispatch<SetStateAction<CrmSetting>>;
  setCrmSettings: Dispatch<SetStateAction<CrmSetting>>;
  brand: Brand;
}

const HubSpotCrmForm: React.FC<HubSpotCrmFormProps> = ({
  formValues,
  setFormValues,
  setCrmSettings,
  brand,
}) => {
  const dispatch = useDispatch();

  const [showPipelinesSetup, setShowPipelinesSetup] = useState<boolean>(false);
  const [lifeCycleStages, setLifecycleStages] = useState<
    HubSpotLifecycleStageOption[]
  >([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (brand) getLifeCycleStages();
  }, [brand]);

  const getLifeCycleStages = async () => {
    try {
      setFetchLoading(true);

      const response = await getHubSpotContactLifecycle(brand?._id);

      const filteredLifecycleStages = response.data.options.filter(
        (option: HubSpotLifecycleStageOption) => !option.hidden,
      );

      setLifecycleStages(filteredLifecycleStages);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleOnSyncLeads = async () => {
    try {
      setLoading(true);
      const response = await syncHubSpotLeads(brand?._id);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `Synced ${response.data.length} leads successfully`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);

      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const getInstallHubSpotAppUrl = () => {
    const scopes = hubSpotScope.split(',').join(' ');
    const redirectUrl = `${clientBaseUrl}/assetz?type=crm`;

    return `${hubSpotOauthBaseUrl}?scope=${scopes}&redirect_uri=${redirectUrl}&client_id=${hubSpotClientId}`;
  };

  const handleShowPipelinesForm = () => {
    setShowPipelinesSetup((prev) => !prev);
  };

  return (
    <Grid item xs={6}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PrimaryButton
            title={`${
              formValues?.hubspotSettings?.accessToken ? 'Change' : 'Connect'
            } HubSpot Account`}
            type="button"
            handleOnClick={() =>
              window.open(getInstallHubSpotAppUrl(), '_blank')
            }
            startIcon={<FaHubspot />}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{ flexDirection: 'column', display: 'flex' }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Account ID
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {formValues?.hubspotSettings?.accountId}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{ flexDirection: 'column', display: 'flex' }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Currency
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {formValues?.hubspotSettings?.currency}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          sx={{ flexDirection: 'column', display: 'flex' }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            Timezone
          </Typography>

          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {formValues?.hubspotSettings?.timezone}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Autocomplete
            ListboxProps={{
              sx: ScrollStyle(),
            }}
            id="leadSources"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  {option.label}
                </li>
              );
            }}
            options={orderArrayOfObject(lifeCycleStages, 'displayOrder')}
            value={
              lifeCycleStages?.find(
                (stage: HubSpotLifecycleStageOption) =>
                  stage.value === formValues?.hubspotSettings?.lifeCycleStageId,
              ) || null
            }
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Default Lifecycle Stage"
                placeholder="Select your default lifecycle stage"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {fetchLoading ? (
                        <CircularProgress
                          size={20}
                          sx={{ marginRight: '10px' }}
                        />
                      ) : null}
                    </>
                  ),
                }}
              />
            )}
            onChange={(_, value: HubSpotLifecycleStageOption) => {
              setFormValues({
                ...formValues,
                hubspotSettings: {
                  ...formValues?.hubspotSettings,
                  lifeCycleStageId: value.value,
                },
              });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <PrimaryButton
            title="Setup Pipelines"
            type="button"
            handleOnClick={handleShowPipelinesForm}
            marginRight5
          />

          {formValues?.hubspotSettings?.accountId &&
          formValues?.hubspotSettings?.accessToken ? (
            <PrimaryButton
              title="Sync Leads"
              type="button"
              handleOnClick={handleOnSyncLeads}
              startIcon={<MdSync />}
              loading={loading}
            />
          ) : null}
        </Grid>
      </Grid>

      <PopupModal
        open={showPipelinesSetup}
        handleClose={handleShowPipelinesForm}
      >
        <HubSpotPipelinesForm
          formValues={formValues}
          setFormValues={setFormValues}
          setCrmSettings={setCrmSettings}
          brand={brand}
          onClose={handleShowPipelinesForm}
        />
      </PopupModal>
    </Grid>
  );
};

export default HubSpotCrmForm;
