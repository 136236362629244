import * as React from 'react';
import { Box, Button } from '@mui/material';

import { DEFAULT_COLOR_THEME } from '../../utils';
import { GoogleAssetsSubMenu as gasSub } from '../../utils/constants/google';

interface GoogleAssetsSubMenuProps {
  setGoogleAssetzSubMenu: any;
  selectedSubmenu: any;
  selectedMainmenu: any;
  checkDirty: any;
}

const GoogleAssetsSubMenu: React.FC<GoogleAssetsSubMenuProps> = ({
  setGoogleAssetzSubMenu,
  selectedSubmenu,
  selectedMainmenu,
  checkDirty,
}) => {
  const assetTypes = gasSub[selectedMainmenu];
  //const assetTypes = ['account'];
  if (!assetTypes.includes(selectedSubmenu) && assetTypes.length > 0) {
    setGoogleAssetzSubMenu(assetTypes[0]);
  }
  const isSelected = (at: any) => {
    if (at === selectedSubmenu) {
      return {
        color: DEFAULT_COLOR_THEME,
        bgcolor: `#ffffff`,
        pointerEvents: `none`,
      };
    } else {
      return {
        color: `#ffffff`,
        bgcolor: DEFAULT_COLOR_THEME,
      };
    }
  };

  if (assetTypes.length === 0) {
    return (
      <Box
        sx={{ width: `100%` }}
        bgcolor={`#ffffff`}
        padding={0}
        margin={0}
        overflow={`hidden`}
      >
        <Box
          sx={{
            display: `flex`,

            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
            bgcolor: DEFAULT_COLOR_THEME,
            color: `#ffffff`,
            paddingTop: 1,
            paddingBottom: 0,
            paddingX: 3,
            width: `100%`,
            // borderTopLeftRadius: 20,
            // borderTopRightRadius: 20,
            height: 60,

            //minHeight: 46.5,
          }}
        ></Box>
      </Box>
    );
  }
  return (
    <Box
      sx={{ width: `100%` }}
      bgcolor={`#ffffff`}
      padding={0}
      margin={0}
      overflow={`hidden`}
    >
      <Box
        sx={{
          display: `flex`,
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          gap: 1,
          bgcolor: DEFAULT_COLOR_THEME,
          color: `#ffffff`,
          paddingTop: 1,
          paddingBottom: 0,
          paddingX: 3,
          width: `100%`,
          // borderTopLeftRadius: 20,
          // borderTopRightRadius: 20,
        }}
      >
        <Box display={`flex`}>
          {assetTypes.map((at) => {
            return (
              <Button
                sx={{ ...isSelected(at), borderRadius: 0 }}
                onClick={() => {
                  checkDirty(() => {
                    setGoogleAssetzSubMenu(at);
                  });
                }}
                variant="contained"
              >
                {at}
              </Button>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
export default GoogleAssetsSubMenu;
