import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { useDispatch } from 'react-redux';
import AssetGoogleAccount from './Google/GGAssetsAccount';
import AssetGoogleBusiness from './Google/GGAssetsBusiness';
import AssetGoogleImages from './Google/GGLAssetsImages';

import { AdsAccountFormFields, Brand } from '../../../types';
import {
  fetchGoogleAdsAccount,
  setGoogleAssetsAccount,
  fetchGoogleAssetsAccounts,
  deleteGoogleAssetsImage,
  saveGoogleCustomerID,
} from '../../../services/googleAds';
import {
  createAdAccount,
  fetchAdsAccountByBrand,
  removeAdAccount,
} from '../../../services/ads';
import { toggleAlert } from '../../../redux/actions';
import { errorMessageParser } from '../../../utils/helpers/ToastHelper';
import CircularLoading from '../../CircularLoading';
import AssetGoogleLocations from './Google/GGLAssetsLocations';
import AssetGoogleCampaigns from './Google/GGAssetsCampaigns';
import AssetGoogleCallouts from './Google/GGAssetsCallouts';
import AssetGoogleConversions from './Google/GGAssetsConversion';

interface GoogleAssetzFormProps {
  defaultRefreshToken: string;
  brand: Brand;
  connectedAccountsIds: string[];
  campaignProvider: string;
  selectedMainmenu: any;
  selectedSubmenu: any;
  setIsDirty: any;
  checkDirty: any;
}

const GoogleAssetzForm: React.FC<GoogleAssetzFormProps> = ({
  defaultRefreshToken,
  brand,
  connectedAccountsIds,
  campaignProvider,
  selectedMainmenu,
  selectedSubmenu,
  setIsDirty,
  checkDirty,
}) => {
  const [googleAdAccounts, setGoogleAdAccounts] = useState<
    AdsAccountFormFields[]
  >([]);
  const [loadingScreen, setLoadingScreen] = useState<boolean>(false);
  const [adAccounts, setAdAccounts] = useState<AdsAccountFormFields[]>([]);
  const [accountsForm, setAccountsForm] = useState<any>({});
  const [businessForm, setBusinessForm] = useState<any>({});
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [databaseImages, setDatabaseImages] = useState<
    {
      url: string;
      height: number;
      width: number;
      asset_id: string;
      public_id: string;
      resource_type: string;
    }[]
  >([]);

  const dispatch = useDispatch();

  const saveBusinessForm = async (businessdata: any) => {
    setLoadingScreen(true);

    setBusinessForm(businessdata);
    try {
      const gacss = await setGoogleAssetsAccount({
        brandId: brand._id,
        ...accountsForm,
        ...businessdata,
      });

      setLoadingScreen(false);
      setShowSaveButton(false);
    } catch (error: any) {
      setLoadingScreen(false);
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    }
  };

  const handleSelectAdsAccount = async (adAccount: AdsAccountFormFields) => {
    try {
      //setLoading(true);
      let temp: AdsAccountFormFields = {
        ...adAccount,
        brand: brand._id,
        manual: true,
        diy: true,
      };
      console.log(`temp`, temp);
      const response = await createAdAccount(temp);

      let tempAccounts: AdsAccountFormFields[] = [];
      if (adAccounts.length === 0) {
        tempAccounts = [response.data];
      } else {
        adAccounts.forEach((account: AdsAccountFormFields) => {
          if (account.accountId !== response.data.accountId) {
            tempAccounts = [...(adAccounts || []), response.data];
          } else {
            if (account.accountId === response.data.accountId) {
              account = response.data;
            }

            tempAccounts = [...tempAccounts, account];
          }
        });
      }

      setAdAccounts(tempAccounts);

      const forGoogleAccounts = {
        brandId: brand._id,
        customer_id: response.data.customerId,
        account_id: response.data.accountId,
      };

      const configureGoogleAccounts = await setGoogleAssetsAccount(
        forGoogleAccounts,
      );

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} ad account was added successfully`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      //setLoading(false);
    }
  };

  const handleRemoveAdsAccount = async (adAccount: AdsAccountFormFields) => {
    try {
      //setLoading(true);
      const response = await removeAdAccount(
        adAccount.accountId,
        brand?._id,
        campaignProvider,
      );

      const tempAccounts = adAccounts.filter(
        (account: AdsAccountFormFields) =>
          account?.accountId !== response.data.accountId,
      );

      setAdAccounts(tempAccounts);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `${response.data.name} ad account was removed`,
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      //setLoading(false);
    }
  };

  const handleInputChange = async () => {
    setShowSaveButton(true);
  };

  const deleteImage = async (imgRecord: string) => {
    const public_id = imgRecord;
    setLoadingScreen(true);

    const deleteImg = await deleteGoogleAssetsImage({
      brandId: brand._id,
      public_id: public_id,
    });

    setLoadingScreen(false);
    fetchGAssetsAccounts();
    dispatch(
      toggleAlert({
        toggle: true,
        message: 'Image was deleted successfully',
      }),
    );
  };

  const fetchGAssetsAccounts = async () => {
    setIsDirty(false);
    setLoadingScreen(true);
    const { data } = await fetchGoogleAssetsAccounts({ brandId: brand._id });
    console.log(`🚀 data`, data);
    if (data && data.doc) {
      setBusinessForm({
        business_name: data.doc.business_name || '',
        tracking_template: data.doc.tracking_template || '',
        phone_numbers: data.doc.phone_numbers || [],
        landing_page_url: data.doc.landing_page_url || [],
        competitors: data.doc.competitors || [],
        target_audience: data.doc.target_audience || '',
      });
      if (data.doc.images) {
        setDatabaseImages(data.doc.images);
      }
    }
    setLoadingScreen(false);
  };

  const getGoogleAdAccounts = async () => {
    try {
      //setLoading(true);
      await fetchGAssetsAccounts();
      const response = await fetchGoogleAdsAccount(
        brand._id,
        defaultRefreshToken,
      );

      const tempAccounts = await fetchAdsAccountByBrand(
        brand._id,
        campaignProvider,
      );

      setAdAccounts(tempAccounts.data);

      setGoogleAdAccounts(response?.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      //setLoading(false);
    }
  };

  const handleSyncGoogleCustomerIds = async (value: string[], brand: Brand) => {
    try {
      console.log(`handleSyncGoogleCustomerIds`);
      console.log(`value`, value);
      console.log(`brand`, brand);
      saveGoogleCustomerID({
        mcc: value,
        brandId: brand._id,
      });
      getGoogleAdAccounts();
    } catch (error: any) {
      console.log(error.message);
    }
  };
  const onRemoveAdAccount = () => {};

  useEffect(() => {
    if (brand) {
      getGoogleAdAccounts();
    }
  }, [brand]);

  return (
    <Box
      position={`relative`}
      display={`flex`}
      justifyContent={`left`}
      alignItems={`start`}
      width={`100%`}
      borderLeft={`1px solid #ccc`}
      borderRight={`1px solid #ccc`}
      borderBottom={`1px solid #ccc`}
      sx={{
        paddingBottom: 2,
        zIndex: 10,
      }}
    >
      <CircularLoading loading={loadingScreen} />
      <Box
        sx={{ width: `100%` }}
        bgcolor={`#ffffff`}
        padding={`10px 10px 0 10px`}
        margin={0}
        overflow={`hidden`}
        display={!selectedMainmenu ? 'none' : 'block'}
      >
        {selectedMainmenu === 'Home' ? (
          <>
            {selectedSubmenu === 'account' ? (
              <AssetGoogleAccount
                brand={brand}
                googleAdAccounts={googleAdAccounts}
                adAccounts={adAccounts}
                handleSyncGoogleCustomerIds={handleSyncGoogleCustomerIds}
                onSelectAdsAccount={handleSelectAdsAccount}
                onRemoveAdAccount={handleRemoveAdsAccount}
                connectedAccountsIds={connectedAccountsIds}
              />
            ) : null}
            {selectedSubmenu === 'business' ? (
              <AssetGoogleBusiness
                brandId={brand._id}
                handleInputChange={handleInputChange}
                submitForm={saveBusinessForm}
                businessForm={businessForm}
                setIsDirty={setIsDirty}
              />
            ) : null}

            {selectedSubmenu === 'location sets' ? (
              <AssetGoogleLocations brand={brand} floatingSave={true} />
            ) : null}

            {selectedSubmenu === 'existing campaigns' ? (
              <AssetGoogleCampaigns defaultRefreshToken={defaultRefreshToken} />
            ) : null}

            {selectedSubmenu === 'callouts' ? (
              <AssetGoogleCallouts brand={brand} />
            ) : null}

            {selectedSubmenu === 'conversion' ? (
              <AssetGoogleConversions brand={brand} />
            ) : null}
          </>
        ) : null}
      </Box>
    </Box>
  );
};
export default GoogleAssetzForm;
