import {
  Typography,
  Box,
  TextField,
  Button,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { DEFAULT_COLOR_THEME, IS_DIY_ADZ } from '../../../../utils';
import { scrollBarGreen } from '../../../../utils/constants/google';
import { FloatingButton } from '../../../Buttons';
import { Close } from '@mui/icons-material';
import {
  CampaignFormFields,
  Brand,
  AdsAccountFormFields,
} from '../../../../types';
import {
  humanizeString,
  snakeCaseToHumanized,
} from '../../../../utils/stringModifier';
import FormHeader from '../../FormHeader';
import styles from '../../../../assets/styles/components/Modal/CampaignModal.module.scss';
import {
  fetchGoogle,
  fetchGoogleAccountDetails,
  fetchGoogleAdsAccount,
  fetchGoogleAssetsByBrand,
  fetchGoogleCampaigns,
  getFromGAcc,
  getGoogleCampaignAndAdgroupsAndAds,
  patchtoGAcc,
  setGoogleAssetsAccount,
} from '../../../../services/googleAds';
import {
  createAdAccount,
  createCampaign,
  deleteCampaign,
  fetchAdsAccountByBrand,
  fetchBrandCampaigns,
} from '../../../../services/ads';
import { campaignProvider } from '../../../../redux/reducers/Campaigns';
import { arrayUniqueFilter } from '../../../../utils/arrayFormatter';
import { adsProvider } from '../../../../utils/constants/facebookAds';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import { errorMessageParser } from '../../../../utils/helpers/ToastHelper';
import CircularLoading from '../../../CircularLoading';

interface AssetGoogleCampaignsProps {
  defaultRefreshToken: string;
}

const AssetGoogleCampaigns: React.FC<AssetGoogleCampaignsProps> = ({
  defaultRefreshToken,
}) => {
  const dispatch = useDispatch();
  const campaignProvider = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const [allCampaigns, setAllCampaigns] = useState<CampaignFormFields[]>([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState<
    CampaignFormFields[]
  >([]);
  const [inhouseCampaigns, setInhouseCampaigns] = useState<
    CampaignFormFields[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleOnSelectCampaign = async (campaign: CampaignFormFields) => {
    console.log(campaign);
    const temp = [...selectedCampaigns, campaign];
    setSelectedCampaigns(temp);

    await patchtoGAcc({
      brandId: brand._id,
      command: { $push: { campaigns: campaign.id } },
    }).then((res) => console.log(res));
    setLoading(false);
    return dispatch(
      toggleAlert({
        toggle: true,
        message: `${campaign.name} campaign was added successfully`,
      }),
    );
  };

  const handleOnRemoveCampaign = async (campaign: CampaignFormFields) => {
    console.log(campaign);
    const temp = selectedCampaigns.filter(
      (e: CampaignFormFields) => e.id !== campaign.id,
    );
    setSelectedCampaigns(temp);

    await patchtoGAcc({
      brandId: brand._id,
      command: { $pull: { campaigns: campaign.id } },
    }).then((res) => console.log(res));
    setLoading(false);
    return dispatch(
      toggleAlert({
        toggle: true,
        message: `${campaign.name} campaign was removed`,
      }),
    );
  };

  const selectedOption = (o: any) => {
    return !!selectedCampaigns.find((e: any) => e.id === o.id && !e.deleted);
  };

  const getCampaigns = async () => {
    try {
      setLoading(true);
      // const campaigns = await fetchBrandCampaigns(brand?._id, 'google');
      const gacc = await getFromGAcc({
        brandId: brand._id,
      }).then((res) => res.data);
      const campaignscreatedusingplatformrssnames = await fetchGoogle({
        brandId: brand._id,
        db: 'googleAds',
      }).then((res) => res.map((ad: any) => ad.campaign));
      const rawCampaigns = await fetchGoogleCampaigns(brand?._id);
      console.log(`$rawCampaigns`);
      console.log(rawCampaigns);
      console.log(`$gacc`);
      console.log(gacc);

      console.log(`$campaignscreatedusingplatform`);
      console.log(campaignscreatedusingplatformrssnames);

      const campaignscreatedusingplatform = rawCampaigns
        ?.filter((c: any) =>
          campaignscreatedusingplatformrssnames.includes(
            c.campaign.resourceName,
          ),
        )
        .map((c: any) => c.campaign);

      console.log(`$campaignscreatedusingplatform`);
      console.log(campaignscreatedusingplatform);

      const campaignsNOTcreatedusingplatform =
        rawCampaigns
          ?.filter(
            (c: any) =>
              !campaignscreatedusingplatformrssnames.includes(
                c.campaign.resourceName,
              ),
          )
          .map((c: any) => c.campaign) || [];

      console.log(`$campaignsNOTcreatedusingplatform`);
      console.log(campaignsNOTcreatedusingplatform);

      const displayedcampaigns = [...(campaignscreatedusingplatform || [])];
      const campaignList: any[] = [];

      rawCampaigns
        ?.map((c: any) => c.campaign)
        .forEach((c: any) => {
          if (!campaignList.find((e: any) => e.id === c.id)) {
            campaignList.push(c);
          }
        });
      campaignsNOTcreatedusingplatform
        ?.filter((c: any) => gacc?.campaigns?.includes(c.id))
        .forEach((c: any) => {
          if (!displayedcampaigns.find((e: any) => e.id === c.id)) {
            displayedcampaigns.push(c);
          }
        });

      console.log(`$displayedcampaigns`);
      console.log(displayedcampaigns);

      setInhouseCampaigns(campaignscreatedusingplatform || []);
      setSelectedCampaigns(displayedcampaigns);
      setAllCampaigns(campaignList || []);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (brand) {
      getCampaigns();
    }
  }, []);

  return (
    <div className={styles.campaigns}>
      <CircularLoading loading={loading} />
      <FormHeader title={`Select Google Campaigns`} />

      <Autocomplete
        id="campaigns"
        multiple
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          );
        }}
        options={allCampaigns.sort((a, b) => -b.name?.localeCompare(a.name))}
        getOptionDisabled={(option) => selectedOption(option)}
        value={selectedCampaigns}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Campaigns"
            placeholder="Select your campaigns"
          />
        )}
        disableClearable
        onChange={async (e: any, value: any[], reason, detail) => {
          setLoading(true);
          if (reason === 'selectOption') {
            handleOnSelectCampaign(detail.option as CampaignFormFields);
          } else if (reason === 'removeOption') {
            handleOnRemoveCampaign(detail.option as CampaignFormFields);
          }
        }}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name}
              {...getTagProps({ index })}
              disabled={inhouseCampaigns
                .map((campaign: CampaignFormFields) => campaign.id)
                .includes(option.id)}
            />
          ))
        }
      />

      <Grid container spacing={1} mt={1}>
        {selectedCampaigns?.map((campaign: CampaignFormFields) => {
          return campaign.deleted ? null : (
            <Grid item xs={12} sm={6} lg={3} key={campaign.id}>
              <Card sx={{ height: '100%' }}>
                <CardHeader
                  subheader={campaign.name}
                  subheaderTypographyProps={{
                    sx: {
                      fontWeight: 'bold',
                      color: 'black',
                      textAlign: 'center',
                    },
                  }}
                />

                <CardContent sx={{ paddingTop: 0 }}>
                  <Typography variant="body2" fontWeight="bold" component="div">
                    ID:
                    <Typography variant="body2" display="inline" ml={1}>
                      {campaign.id}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Status:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(campaign.status)}
                    </Typography>
                  </Typography>

                  <Typography variant="body2" fontWeight="bold" component="div">
                    Objective:
                    <Typography variant="body2" display="inline" ml={1}>
                      {humanizeString(snakeCaseToHumanized(campaign.objective))}
                    </Typography>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default AssetGoogleCampaigns;
