import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Brand, ChangeEventType } from '../../../../types';
import { CrmSetting } from '../../../../types/ICrm';
import { HtmlTooltip } from '../../../Tooltip';
import { errorMessageParser } from '../../../../utils/helpers/ToastHelper';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import { createOrUpdateCrmSettings } from '../../../../services/crm';
import { FloatingButton } from '../../../Buttons';
import { useForm } from 'react-hook-form';
import clientTetherLogo from '../../../../assets/images/client-tether-logo.png';
import hubspotLogo from '../../../../assets/images/hubspot-logo.png';
import { NumericCustomField } from '../../../TextField';
import ClientTetherCrmForm from './ClientTetherCrmForm';
import { CrmProvider } from '../../../../utils';
import HubSpotCrmForm from './HubSpot/HubSpotCrmForm';

interface CrmPlatform {
  name: string;
  value: string;
  logo: any;
}

const CRM_PLATFORMS: CrmPlatform[] = [
  {
    name: 'Client Tether',
    value: CrmProvider.CLIENT_TETHER,
    logo: clientTetherLogo,
  },
  {
    name: 'HubSpot',
    value: CrmProvider.HUBSPOT,
    logo: hubspotLogo,
  },
];

interface FacebookCrmSettingsFormProps {
  brand: Brand;
  crmSettings: CrmSetting;
  setCrmSettings: Dispatch<SetStateAction<CrmSetting>>;
}

const CrmSettingsForm: React.FC<FacebookCrmSettingsFormProps> = ({
  brand,
  crmSettings,
  setCrmSettings,
}) => {
  const dispatch = useDispatch();
  const {
    formState: { errors },
    register,
    handleSubmit,
    clearErrors,
  } = useForm({ mode: 'onSubmit' });
  const [formValues, setFormValues] = useState<CrmSetting>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (crmSettings) {
      setFormValues({ ...crmSettings });
    }
  }, [crmSettings]);

  const handleOnEnable = (e: any, provider: string) => {
    let providers: string[] = [];
    if (e.target.checked) {
      providers = [
        ...(formValues?.providers?.filter((p: string) => p !== provider) || []),
        provider,
      ];
    } else {
      providers = formValues.providers.filter((p: string) => p !== provider);
    }

    setFormValues({ ...formValues, providers });
  };

  const handleOnSubmit = async () => {
    try {
      setLoading(true);

      const response = await createOrUpdateCrmSettings(brand?._id, formValues);

      setCrmSettings(response.data);
      setFormValues(response.data);
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'CRM Setting updated successfully',
        }),
      );
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const renderForm = (provider: string) => {
    if (
      formValues?.providers?.includes(provider) &&
      provider === CrmProvider.CLIENT_TETHER
    ) {
      return (
        <ClientTetherCrmForm
          formValues={formValues}
          setFormValues={setFormValues}
          setCrmSettings={setCrmSettings}
          brand={brand}
          register={register}
          clearErrors={clearErrors}
          errors={errors}
        />
      );
    }

    if (
      formValues?.providers?.includes(provider) &&
      provider === CrmProvider.HUBSPOT
    ) {
      return (
        <HubSpotCrmForm
          formValues={formValues}
          setFormValues={setFormValues}
          setCrmSettings={setCrmSettings}
          brand={brand}
        />
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <NumericCustomField
                value={formValues?.averageLifetimeValue}
                onChangeValue={(values) =>
                  setFormValues({
                    ...formValues,
                    averageLifetimeValue: values.floatValue,
                  })
                }
                id="averageLifetimeValue"
                name="averageLifetimeValue"
                label="Average Lifetime Value"
                fullWidth
                fixedDecimal
                helperText={
                  <Fragment>
                    <HtmlTooltip
                      disableInteractive
                      title={
                        <Fragment>
                          <Box
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            Formula
                          </Box>

                          <div>AUV x Royalty Fee Percentage x Years</div>

                          <Box
                            component="div"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            Example
                          </Box>

                          <Box
                            component="div"
                            sx={{
                              flexDirection: 'column',
                            }}
                          >
                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              $1,000,000 (AUV)
                            </Box>

                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              0.05 (5% Royalty Fee)
                            </Box>

                            <Box
                              sx={{
                                justifyContent: 'space-between',
                                display: 'flex',
                              }}
                            >
                              <div>x</div>

                              <div>10 (Years)</div>
                            </Box>

                            <div>
                              <Divider />
                            </div>

                            <Box
                              sx={{
                                justifyContent: 'flex-end',
                                display: 'flex',
                              }}
                            >
                              $500,000 (Average Lifetime Value)
                            </Box>
                          </Box>
                        </Fragment>
                      }
                    >
                      <Typography
                        variant="caption"
                        sx={{
                          textDecoration: 'underline',
                          color: 'blue',
                          cursor: 'pointer',
                        }}
                      >
                        How to calculate your Average Lifetime Value?
                      </Typography>
                    </HtmlTooltip>
                  </Fragment>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <NumericCustomField
                value={formValues?.averageCommission}
                onChangeValue={(values) =>
                  setFormValues({
                    ...formValues,
                    averageCommission: values.floatValue,
                  })
                }
                id="averageCommission"
                name="averageCommission"
                label="Average Commission"
                fullWidth
                fixedDecimal
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="standard"
                type="number"
                id="averageUnitSold"
                name="averageUnitSold"
                label="Average Unit Sold"
                onChange={(e: ChangeEventType) =>
                  setFormValues({
                    ...formValues,
                    [e.target.name]: parseInt(e.target.value),
                  })
                }
                value={formValues?.averageUnitSold}
                placeholder="0"
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{
                  '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                    {
                      display: 'none',
                    },
                  '& input[type=number]': {
                    MozAppearance: 'textfield',
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          Please choose the CRM you want to integrate:
        </Grid>

        <Grid item xs={12}>
          {CRM_PLATFORMS.map((platform: CrmPlatform, index: number) => {
            return (
              <Box key={platform.value} mt={index > 0 ? 2 : 0}>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="status">
                      <Box
                        sx={{
                          display: 'flex',
                          width: '100%',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          component="img"
                          src={platform.logo}
                          sx={{
                            width: '20px',
                            marginRight: '10px',
                          }}
                        />

                        {platform.name}
                      </Box>
                    </FormLabel>

                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            formValues?.providers?.includes(platform.value) ||
                            false
                          }
                          onChange={(e: any) =>
                            handleOnEnable(e, platform.value)
                          }
                        />
                      }
                      label={
                        formValues?.providers?.includes(platform.value)
                          ? 'Enabled'
                          : 'Disabled'
                      }
                    />
                  </FormControl>
                </Grid>

                {renderForm(platform.value)}

                <Grid item xs={12} my={2}>
                  <Divider />
                </Grid>
              </Box>
            );
          })}
        </Grid>
      </Grid>

      <FloatingButton
        title="Save"
        onClick={handleSubmit(handleOnSubmit)}
        loading={loading}
        bottom="60px"
        fixed
      />
    </Box>
  );
};

export default CrmSettingsForm;
