import {
  Typography,
  Box,
  TextField,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { scrollBarGreen } from '../../../../utils/constants/google';
import { FloatingButton } from '../../../Buttons';
import { Close } from '@mui/icons-material';
import { fetchGoogleTrackingTemplate } from '../../../../services/googleAds';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions';
import CircularLoading from '../../../CircularLoading';

interface AssetGoogleBusinessProps {
  submitForm: any;
  handleInputChange: any;
  businessForm: any;
  setIsDirty: any;
  brandId: string;
}

const AssetGoogleBusiness: React.FC<AssetGoogleBusinessProps> = ({
  submitForm,
  handleInputChange,
  businessForm,
  setIsDirty,
  brandId,
}) => {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [bname, setBname] = useState('');
  const [trackingTemplate, setTrackingTemplate] = useState(
    `{lpurl}?campaign={campaignid}&content={creative}&keyword={keyword}&utm_source=google&utm_medium=cpc&utm_campaign={_campaign}&utm_content={_adgroup}`,
  );
  const [landingPageURLs, setLandingPageURLs] = useState([
    { label: '', url: '' },
    { label: '', url: '' },
    { label: '', url: '' },
    { label: '', url: '' },
  ]);
  const [phoneNumbers, setPhoneNumbers] = useState([
    { label: '', number: '' },
    { label: '', number: '' },
    { label: '', number: '' },
    { label: '', number: '' },
  ]);
  const [competitors, setCompetitors] = useState([
    { name: '', url: '' },
    { name: '', url: '' },
    { name: '', url: '' },
    { name: '', url: '' },
  ]);
  const [language, setLanguage] = useState('en');
  const phoneRef = useRef(null);
  const [targetAudience, setTargetAudience] = useState('');

  useEffect(() => {
    if (businessForm) {
      setBname(businessForm.business_name);
      setTrackingTemplate(businessForm.tracking_template);
      if (businessForm?.landing_page_url) {
        setLandingPageURLs(
          businessForm?.landing_page_url?.length < 4
            ? businessForm?.landing_page_url?.concat(
                Array(4 - businessForm.landing_page_url.length).fill({
                  label: '',
                  url: '',
                }),
              )
            : businessForm?.landing_page_url,
        );
      }

      if (businessForm?.competitors) {
        setCompetitors(
          businessForm?.competitors?.length < 4
            ? businessForm.competitors.concat(
                Array(4 - businessForm.competitors.length).fill({
                  name: '',
                  url: '',
                }),
              )
            : businessForm.competitors,
        );
      }
      if (businessForm?.phone_numbers) {
        setPhoneNumbers(
          businessForm?.phone_numbers?.length < 4
            ? businessForm?.phone_numbers?.concat(
                Array(4 - businessForm.phone_numbers.length).fill({
                  label: '',
                  number: '',
                }),
              )
            : businessForm.phone_numbers,
        );
      }

      setTargetAudience(businessForm.target_audience);
    }
  }, [businessForm]);

  return (
    <Box
      sx={{
        px: 4,
      }}
    >
      <CircularLoading loading={saving} />
      <FloatingButton
        title="Save"
        fixed
        onClick={() => {
          submitForm({
            business_name: bname,
            tracking_template: trackingTemplate,
            phone_numbers: phoneNumbers,
            landing_page_url: landingPageURLs,
            target_audience: targetAudience,
            competitors: competitors,
          }).then(() => {
            setIsDirty(false);
            dispatch(
              toggleAlert({
                toggle: true,
                message: 'Business changes saved',
              }),
            );
          });
        }}
      />
      <Box>
        <Box>
          <Typography variant="h6">Business Setup</Typography>
        </Box>
        <Box sx={{ my: 2, display: `flex`, gap: 2 }}>
          <Box sx={{ width: `80%` }}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              id="budgetOffset"
              name="budgetOffset"
              label="Business Name"
              onInput={handleInputChange}
              onChange={(e) => {
                setIsDirty(true);
                setBname(e.target.value);
              }}
              value={bname}
              placeholder="Enter Business Name"
              InputLabelProps={{ shrink: true }}
              size="small"
              sx={{
                '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                  {
                    display: 'none',
                  },
                '& input[type=number]': {
                  MozAppearance: 'textfield',
                },
                backgroundColor: `#ffffff`,
              }}
            />
          </Box>
          <Box sx={{ width: `20%` }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="language-select">Language</InputLabel>
              <Select
                labelId="language-select"
                id="language-input"
                value={language}
                label="Language"
                onChange={(e) => {
                  setIsDirty(true);
                  setLanguage(e.target.value);
                }}
              >
                <MenuItem value={'en'}>English</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ my: 2, position: `relative` }}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            id="budgetOffset"
            name="budgetOffset"
            label="Tracking Template"
            onInput={handleInputChange}
            onChange={(e) => {
              setIsDirty(true);
              setTrackingTemplate(e.target.value);
            }}
            value={trackingTemplate}
            placeholder="Enter Tracking Template"
            InputLabelProps={{ shrink: true }}
            size="small"
            sx={{
              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                {
                  display: 'none',
                },
              '& input[type=number]': {
                MozAppearance: 'textfield',
              },
            }}
          />

          {trackingTemplate === '' ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSaving(true);
                  fetchGoogleTrackingTemplate(brandId).then((res) => {
                    console.log(res);
                    console.log(res.data.trackingUrlTemplate);

                    setTrackingTemplate(res.data.trackingUrlTemplate);
                    setSaving(false);
                  });
                }}
                sx={{
                  float: `right`,
                  position: `absolute`,
                  top: `0`,
                  right: `0`,
                  marginTop: `2px`,
                }}
              >
                Fetch Tracking Template from google account
              </Button>
            </>
          ) : null}
        </Box>
        <Box
          sx={{
            display: `flex`,
            gap: 2,
          }}
        >
          <Box
            sx={{
              width: `33%`,
            }}
          >
            <Box
              sx={{
                backgroundColor: `#ffffff`,
                padding: `0px 5px`,
                color: `${DEFAULT_COLOR_THEME}`,
                fontSize: `1.2rem`,
              }}
            >
              Phone Numbers
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                width: `100%`,
                border: `1px solid ${DEFAULT_COLOR_THEME}`,
                borderTopLeftRadius: 2,
                borderTopRightRadius: 2,
                padding: `0px 10px 0px 10px`,
                position: `relative`,
                maxHeight: `550px`,
                overflowY: `auto`,
                backgroundColor: `#f5f5f5`,
                ...scrollBarGreen,
              }}
            >
              <Box>
                {phoneNumbers?.map((item, index) => {
                  return (
                    <>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                          width: `100%`,
                          border: `1px solid ${DEFAULT_COLOR_THEME}`,
                          padding: `10px 10px 0px 10px`,
                          position: `relative`,
                          mt: 2,
                          mb: 1,
                          backgroundColor: `#e6ffd4`,
                        }}
                      >
                        <Box
                          sx={{
                            position: `absolute`,
                            top: `-10px`,
                            left: `10px`,
                            backgroundColor: `#e6ffd4`,
                            padding: `0px 5px`,
                            color: `${DEFAULT_COLOR_THEME}`,
                          }}
                        >
                          Phone Number {index + 1}
                        </Box>
                        <Button
                          sx={{
                            position: `absolute`,
                            top: `-10px`,
                            right: `-10px`,
                            height: `25px`,
                            width: `25px`,
                            minWidth: `25px`,
                            backgroundColor: DEFAULT_COLOR_THEME,
                            padding: `0px 0px`,
                            borderRadius: `50%`,
                            '&:hover': {
                              backgroundColor: `#ff3333`,
                              opacity: 0.4,
                            },
                            display:
                              phoneNumbers.length === 1 ? 'none' : 'flex',
                          }}
                        >
                          <Close
                            sx={{
                              height: `20px`,
                              width: `20px`,
                              fill: `#ffffff`,
                            }}
                            onClick={() => {
                              const temp = [...phoneNumbers];
                              temp.splice(index, 1);
                              setIsDirty(true);
                              setPhoneNumbers(temp);
                            }}
                          />
                        </Button>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          id={`phoneNumberLabel${index + 1}`}
                          name={`phoneNumberLabel${index + 1}`}
                          label={`Label`}
                          placeholder={`Phone Number ${index + 1} Label`}
                          onInput={handleInputChange}
                          onChange={(e) => {
                            setIsDirty(true);
                            const temp = [...phoneNumbers];
                            temp[index].label = e.target.value;
                            setPhoneNumbers(temp);
                          }}
                          value={item.label}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          sx={{
                            my: 1,
                            borderRadius: 2,
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                              {
                                display: 'none',
                              },
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                            backgroundColor: `#ffffff`,
                          }}
                        />

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          id={`phoneNumberNumber${index + 1}`}
                          name={`phoneNumberNumber${index + 1}`}
                          label={`Number`}
                          value={phoneNumbers[index].number}
                          placeholder={`Phone Number ${index + 1}`}
                          onInput={handleInputChange}
                          onChange={(e) => {
                            setIsDirty(true);
                            const temp = [...phoneNumbers];
                            temp[index].number = e.target.value;
                            setPhoneNumbers(temp);
                          }}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          sx={{
                            my: 1,
                            borderRadius: 2,
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                              {
                                display: 'none',
                              },
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                            backgroundColor: `#ffffff`,
                          }}
                        />
                      </Box>
                    </>
                  );
                })}
              </Box>
              <Box ref={phoneRef} />
            </Box>
            <Button
              sx={{
                width: '100%',
                bgcolor: `#096f4d`,
                color: `#ffffff`,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
                '&:hover': {
                  backgroundColor: `#096f4d`,
                  opacity: 0.8,
                },
              }}
              onClick={() => {
                //scroll to the bottom of the phone number section
                phoneRef.current.scrollIntoView({ behavior: 'smooth' });
                setPhoneNumbers([...phoneNumbers, { label: '', number: '' }]);
              }}
            >
              Add Phone Number
            </Button>
          </Box>

          <Box
            sx={{
              width: `33%`,
            }}
          >
            <Box
              sx={{
                backgroundColor: `#ffffff`,
                padding: `0px 5px`,
                color: `${DEFAULT_COLOR_THEME}`,
                fontSize: `1.2rem`,
              }}
            >
              Landing Page URLs
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                width: `100%`,
                border: `1px solid ${DEFAULT_COLOR_THEME}`,
                borderRadius: 2,
                padding: `0px 10px 0px 10px`,
                position: `relative`,
                maxHeight: `550px`,
                overflowY: `auto`,
                backgroundColor: `#f5f5f5`,
                ...scrollBarGreen,
              }}
            >
              <Box>
                {landingPageURLs?.map((item, index) => {
                  return (
                    <>
                      <Box
                        sx={{
                          display: `flex`,
                          flexDirection: `column`,
                          width: `100%`,
                          border: `1px solid ${DEFAULT_COLOR_THEME}`,
                          padding: `10px 10px 0px 10px`,
                          position: `relative`,
                          backgroundColor: `#e6ffd4`,
                          mt: 2,
                          mb: 1,
                        }}
                      >
                        <Box
                          sx={{
                            position: `absolute`,
                            top: `-10px`,
                            left: `10px`,
                            backgroundColor: `#e6ffd4`,
                            padding: `0px 5px`,
                            color: `${DEFAULT_COLOR_THEME}`,
                          }}
                        >
                          Landing Page {index + 1}
                        </Box>
                        <Button
                          sx={{
                            position: `absolute`,
                            top: `-10px`,
                            right: `-10px`,
                            height: `25px`,
                            width: `25px`,
                            minWidth: `25px`,
                            backgroundColor: DEFAULT_COLOR_THEME,
                            padding: `0px 0px`,
                            borderRadius: `50%`,
                            '&:hover': {
                              backgroundColor: `#ff3333`,
                              opacity: 0.4,
                            },
                            display:
                              landingPageURLs.length === 1 ? 'none' : 'flex',
                          }}
                        >
                          <Close
                            sx={{
                              height: `20px`,
                              width: `20px`,
                              fill: `#ffffff`,
                            }}
                            onClick={() => {
                              const temp = [...landingPageURLs];
                              temp.splice(index, 1);
                              setIsDirty(true);
                              setLandingPageURLs(temp);
                            }}
                          />
                        </Button>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          id={`landingPageLabel${index + 1}`}
                          name={`landingPageLabel${index + 1}`}
                          label={`Label`}
                          onInput={handleInputChange}
                          onChange={(e) => {
                            setIsDirty(true);
                            const temp = [...landingPageURLs];
                            temp[index].label = e.target.value;
                            setLandingPageURLs(temp);
                          }}
                          value={landingPageURLs[index].label}
                          placeholder={`Landing Page ${index + 1} Label`}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          sx={{
                            my: 1,
                            borderRadius: 2,
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                              {
                                display: 'none',
                              },
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                            backgroundColor: `#ffffff`,
                          }}
                        />

                        <TextField
                          fullWidth
                          variant="outlined"
                          type="text"
                          id={`landingPageUrl${index + 1}`}
                          name={`landingPageUrl${index + 1}`}
                          label={`URL`}
                          value={landingPageURLs[index].url}
                          placeholder={`Landing Page ${index + 1} URL`}
                          onInput={handleInputChange}
                          onChange={(e) => {
                            const temp = [...landingPageURLs];
                            temp[index].url = e.target.value;
                            setLandingPageURLs(temp);
                          }}
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          sx={{
                            my: 1,
                            borderRadius: 2,
                            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                              {
                                display: 'none',
                              },
                            '& input[type=number]': {
                              MozAppearance: 'textfield',
                            },
                            backgroundColor: `#ffffff`,
                          }}
                        />
                      </Box>
                    </>
                  );
                })}
              </Box>
            </Box>
            <Button
              sx={{
                width: '100%',
                bgcolor: `#096f4d`,
                color: `#ffffff`,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
                '&:hover': {
                  backgroundColor: `#096f4d`,
                  opacity: 0.8,
                },
              }}
              onClick={() => {
                setLandingPageURLs([
                  ...landingPageURLs,
                  { label: '', url: '' },
                ]);
              }}
            >
              Add Landing Page URL
            </Button>
          </Box>

          <Box
            sx={{
              width: `33%`,
            }}
          >
            <Box
              sx={{
                backgroundColor: `#ffffff`,
                padding: `0px 5px`,
                color: `${DEFAULT_COLOR_THEME}`,
                fontSize: `1.2rem`,
              }}
            >
              Competitors
            </Box>
            <Box
              sx={{
                display: `flex`,
                flexDirection: `column`,
                width: `100%`,
                border: `1px solid ${DEFAULT_COLOR_THEME}`,
                borderRadius: 2,
                padding: `0px 10px 0px 10px`,
                position: `relative`,
                maxHeight: `550px`,
                overflowY: `auto`,
                backgroundColor: `#f5f5f5`,
                ...scrollBarGreen,
              }}
            >
              <Box>
                <Box>
                  {competitors?.map((item, index) => {
                    return (
                      <>
                        <Box
                          sx={{
                            display: `flex`,
                            flexDirection: `column`,
                            width: `100%`,
                            border: `1px solid ${DEFAULT_COLOR_THEME}`,
                            backgroundColor: `#e6ffd4`,
                            padding: `10px 10px 0px 10px`,
                            position: `relative`,
                            mt: 2,
                            mb: 1,
                          }}
                        >
                          <Box
                            sx={{
                              position: `absolute`,
                              top: `-10px`,
                              left: `10px`,
                              backgroundColor: `#e6ffd4`,
                              padding: `0px 5px`,
                              color: `${DEFAULT_COLOR_THEME}`,
                            }}
                          >
                            Competitor {index + 1}
                          </Box>
                          <Button
                            sx={{
                              position: `absolute`,
                              top: `-10px`,
                              right: `-10px`,
                              height: `25px`,
                              width: `25px`,
                              minWidth: `25px`,
                              backgroundColor: DEFAULT_COLOR_THEME,
                              padding: `0px 0px`,
                              borderRadius: `50%`,
                              '&:hover': {
                                backgroundColor: `#ff3333`,
                                opacity: 0.4,
                              },
                              display:
                                competitors.length === 1 ? 'none' : 'flex',
                            }}
                          >
                            <Close
                              sx={{
                                height: `20px`,
                                width: `20px`,
                                fill: `#ffffff`,
                              }}
                              onClick={() => {
                                const temp = [...phoneNumbers];
                                temp.splice(index, 1);
                                setIsDirty(true);
                                setPhoneNumbers(temp);
                              }}
                            />
                          </Button>
                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            id={`competitorName${index + 1}`}
                            name={`competitorName${index + 1}`}
                            label={`Name`}
                            placeholder={`Competitor ${index + 1} Name`}
                            onInput={handleInputChange}
                            onChange={(e) => {
                              setIsDirty(true);
                              const temp = [...competitors];
                              temp[index].name = e.target.value;
                              setCompetitors(temp);
                            }}
                            value={competitors[index].name}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            sx={{
                              my: 1,
                              borderRadius: 2,
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                {
                                  display: 'none',
                                },
                              '& input[type=number]': {
                                MozAppearance: 'textfield',
                              },
                              backgroundColor: `#ffffff`,
                            }}
                          />

                          <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            id={`phoneNumberNumber${index + 1}`}
                            name={`phoneNumberNumber${index + 1}`}
                            label={`URL`}
                            placeholder={`Competitor ${index + 1} URL`}
                            value={competitors[index].url}
                            onInput={handleInputChange}
                            onChange={(e) => {
                              setIsDirty(true);
                              const temp = [...competitors];
                              temp[index].url = e.target.value;
                              setCompetitors(temp);
                            }}
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            sx={{
                              my: 1,
                              borderRadius: 2,
                              '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                                {
                                  display: 'none',
                                },
                              '& input[type=number]': {
                                MozAppearance: 'textfield',
                              },
                              backgroundColor: `#ffffff`,
                            }}
                          />
                        </Box>
                      </>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Button
              sx={{
                width: '100%',
                bgcolor: `#096f4d`,
                color: `#ffffff`,
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
                '&:hover': {
                  backgroundColor: `#096f4d`,
                  opacity: 0.8,
                },
              }}
              onClick={() => {
                setCompetitors([...competitors, { name: '', url: '' }]);
              }}
            >
              Add Competitor
            </Button>
          </Box>
        </Box>
        <Box sx={{ my: 2, display: 'none' }}>
          <Typography variant="h6">Target Audience</Typography>
          <TextField
            multiline
            aria-label="minimum height"
            minRows={3}
            placeholder="Target Audience Who is your ideal/typical customer/client?"
            style={{ width: '100%' }}
            value={targetAudience}
            onInput={handleInputChange}
            onChange={(e) => {
              setIsDirty(true);
              setTargetAudience(e.target.value);
            }}
          ></TextField>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetGoogleBusiness;
