import {
  Box,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GoogleAssetsContainer } from '../../../../utils/constants/google';
import { fetchGoogleCallouts } from '../../../../services/googleAds';
import CircularLoading from '../../../CircularLoading';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';

interface AssetGoogleCalloutsProps {
  brand: any;
}

const AssetGoogleCallouts: React.FC<AssetGoogleCalloutsProps> = ({ brand }) => {
  const dispatch = useDispatch();
  const [callouts, setCallouts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [calloutArea, setCalloutArea] = useState<string>('');
  const [newCallouts, setNewCallouts] = useState<string[]>([]);
  const handleInputChange = (e: any) => {
    setCalloutArea(e.target.value);
  };

  const saveCallouts = () => {
    const filtered = newCallouts.filter((c) => c !== '');
    setNewCallouts(filtered);
    setCalloutArea(filtered.join('\n'));

    //check if callouts are less than 25 characters
    const invalidCallouts = filtered.filter((c) => c.length > 25);
    if (invalidCallouts.length > 0) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: `Callouts must have a maximum of 25 characters`,
          type: 'error',
        }),
      );
      return;
    }
  };

  useEffect(() => {
    console.log(`🚀 brand`, brand);
    fetchGoogleCallouts({ brandId: brand._id }).then((response) => {
      console.log(`🚀 response`, response);
      console.log(
        `🚀 AUTOMATICALLY_CREATED`,
        response.filter((c: any) => c.asset.source === 'AUTOMATICALLY_CREATED')
          .length,
      );
      console.log(
        `🚀 ADVERTISER`,
        response.filter((c: any) => c.asset.source === 'ADVERTISER').length,
      );
      setCallouts(
        response
          .map((c: any) => {
            return {
              calloutText: c.asset.calloutAsset.calloutText,
              resourceName: c.asset.resourceName,
            };
          })
          .sort((a: any, b: any) => {
            //sort alphabetically
            if (a.calloutText < b.calloutText) {
              return -1;
            }
            if (a.calloutText > b.calloutText) {
              return 1;
            }
            return 0;
          }),
      );
      setIsLoading(false);
    });
  }, []);
  return (
    <Box sx={GoogleAssetsContainer}>
      <CircularLoading loading={isLoading} />
      <Typography variant="h5" fontWeight={800} textAlign={`center`}>
        Google Callouts
      </Typography>

      <Box
        sx={{
          display: 'flex',

          justifyContent: 'justify-between',
          alignItems: 'start',
        }}
      >
        <Box
          sx={{
            width: `100%`,
            paddingX: `10px`,
          }}
        >
          <Typography variant="h6" color={DEFAULT_COLOR_THEME}>
            Existing Callouts From Google [{callouts?.length}]
          </Typography>

          {callouts.map((callout, i) => (
            <Box
              key={`callout-${i}`}
              sx={{
                paddingBottom: 1,
                marginBottom: 1,
                borderBottom: '1px solid #ccc',
              }}
            >
              <Box>
                <Typography
                  sx={{
                    display: 'inline',
                    fontSize: 10,
                  }}
                >
                  {i + 1}.
                </Typography>{' '}
                {callout.calloutText}
              </Box>
              <Box
                sx={{
                  fontStyle: 'italic',
                  fontSize: 10,
                  color: '#cacaca',
                }}
              >
                {callout.resourceName}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            width: `100%`,
            paddingX: `10px`,
          }}
        >
          <Typography variant="h6" color={DEFAULT_COLOR_THEME}>
            Add New Callouts{' '}
            <Tooltip
              title="Callouts must have a maximum of 25 characters"
              placement="right"
            >
              <i>
                <IoMdInformationCircleOutline />
              </i>
            </Tooltip>
          </Typography>
          <TextField
            multiline
            aria-label="minimum height"
            minRows={5}
            placeholder="Add Callout Texts separated by new line"
            style={{ width: '100%' }}
            value={calloutArea}
            onInput={handleInputChange}
            onChange={(e) => {
              setCalloutArea(e.target.value);
              setNewCallouts(e.target.value.split('\n'));
            }}
          ></TextField>
          <Box sx={{ marginTop: 2 }}>
            {newCallouts.map((callout, i) => (
              <Box
                key={`new-callout-${i}`}
                sx={{
                  paddingBottom: 1,
                  marginBottom: 1,
                  paddingX: 1,
                  position: 'relative',
                  backgroundColor: callout.length > 25 ? '#f8d7da' : 'inherit',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      display: 'inline',
                      fontSize: 12,
                    }}
                  >
                    {i + 1}.
                  </Typography>{' '}
                  <TextField
                    placeholder=""
                    variant="standard"
                    size="small"
                    style={{ width: '100%' }}
                    value={callout}
                    sx={{
                      padding: 0,
                      margin: 0,
                      border: 0,
                    }}
                    onChange={(e) => {
                      const nc = [...newCallouts];
                      nc[i] = e.target.value;
                      setNewCallouts(nc);
                      setCalloutArea(nc.join('\n'));
                    }}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    position: 'absolute',
                    right: 8,
                    bottom: 8,
                    textAlign: 'right',
                    fontSize: 10,
                    color: callout.length > 25 ? '#cc0000' : '#cacaca',
                  }}
                >
                  {callout.length}/25 characters
                </Box>
              </Box>
            ))}

            <Button
              variant="contained"
              sx={{
                position: `sticky`,
                bottom: 0,
                right: 0,
                marginBottom: `-20px`,
                float: `right`,
              }}
              onClick={saveCallouts}
            >
              Save Callouts
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetGoogleCallouts;
