import {
  Box,
  Button,
  Card,
  CardHeader,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, Fragment, SetStateAction, useState } from 'react';
import {
  ChangeEventType,
  FBLeadGenForm,
  FBLeadGenQuestionForm,
} from '../../../../../types';
import { constantStringToHumanized } from '../../../../../utils/stringModifier';
import AddIcon from '@mui/icons-material/Add';
import {
  prefilledQuestionIcons,
  prefilledQuestions,
} from '../../../../../utils/helpers/LeadHelpers';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { NestedMenuItem } from 'mui-nested-menu';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import { DEFAULT_COLOR_THEME } from '../../../../../utils/Styling';

interface LeadFormPrefillQuestionFormProps {
  prefillQuestions: FBLeadGenQuestionForm[];
  customQuestions: FBLeadGenQuestionForm[];
  formValues: FBLeadGenForm;
  setFormValues: Dispatch<SetStateAction<FBLeadGenForm>>;
  register: any;
  errors: any;
  clearErrors: any;
}

const LeadFormPrefillQuestionForm: React.FC<
  LeadFormPrefillQuestionFormProps
> = ({
  prefillQuestions,
  customQuestions,
  formValues,
  setFormValues,
  register,
  errors,
  clearErrors,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelectPrefillQuestions = (question: any) => {
    setFormValues({
      ...formValues,
      questions: [...formValues?.questions, { type: question.type }],
    });
    handleClose();
  };

  const handleRemoveQuestion = (type: string) => {
    let tempQuestions: FBLeadGenQuestionForm[] = [];

    prefillQuestions.forEach((question: FBLeadGenQuestionForm) => {
      if (question.type !== type) {
        tempQuestions = [...tempQuestions, question];
      }
    });

    setFormValues({
      ...formValues,
      questions: [...customQuestions, ...tempQuestions],
    });
  };

  const handleOnDrag = (result: any) => {
    let temp: any = [...prefillQuestions];

    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    const [reorderBody] = temp.splice(startIndex, 1);
    temp.splice(endIndex, 0, reorderBody);

    setFormValues({
      ...formValues,
      questions: [...customQuestions, ...temp],
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              Prefill Questions
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              Ask for user information. This will be prefilled from their
              Facebook account.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TextField
              {...register('question_page_custom_headline', { required: true })}
              fullWidth
              required
              variant="standard"
              type="text"
              name="question_page_custom_headline"
              label="Prefill Questions Headline"
              onChange={(e: ChangeEventType) => {
                clearErrors('question_page_custom_headline');
                setFormValues({
                  ...formValues,
                  [e.target.name]: e.target.value,
                });
              }}
              value={formValues?.question_page_custom_headline}
              InputLabelProps={{ shrink: true }}
              size="small"
              helperText={
                (errors.question_page_custom_headline &&
                  `Question headline is required`) || (
                  <Fragment>
                    <Box>
                      Let people know how the information they give you will be
                      used or shared.{' '}
                      <Box component="span">
                        <a
                          href="https://www.facebook.com/business/help/1582353715248057?id=735435806665862"
                          target="_blank"
                          rel="noreferrer"
                        >
                          See Examples
                        </a>
                      </Box>
                    </Box>
                  </Fragment>
                )
              }
              error={!!errors.question_page_custom_headline}
            />
          </Grid>

          {prefillQuestions?.length > 0 ? (
            <Grid item xs={12} sm={6}>
              <DragDropContext onDragEnd={handleOnDrag}>
                <Droppable droppableId="prefillQuestions">
                  {(droppableProvider) => (
                    <ul
                      ref={droppableProvider.innerRef}
                      {...droppableProvider.droppableProps}
                    >
                      {prefillQuestions.map(
                        (question: FBLeadGenQuestionForm, index: number) => {
                          return (
                            <Draggable
                              index={index}
                              key={`prefill-questions-${index}`}
                              draggableId={`${index}`}
                            >
                              {(draggableProvider) => (
                                <Grid container spacing={2}>
                                  <Grid item xs={12} mt={1}>
                                    <Box
                                      component="div"
                                      ref={draggableProvider.innerRef}
                                      {...draggableProvider.draggableProps}
                                      {...draggableProvider.dragHandleProps}
                                    >
                                      <Card>
                                        <CardHeader
                                          subheader={
                                            <Fragment>
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  justifyContent:
                                                    'space-between',
                                                  alignItems: 'center',
                                                  flexDirection: 'row',
                                                }}
                                              >
                                                <Box component="span">
                                                  <DragIndicatorOutlinedIcon />
                                                </Box>

                                                {constantStringToHumanized(
                                                  question.type,
                                                )}

                                                <Box>
                                                  <IconButton
                                                    onClick={() =>
                                                      handleRemoveQuestion(
                                                        question.type,
                                                      )
                                                    }
                                                    edge="end"
                                                  >
                                                    <DeleteIcon
                                                      sx={{ color: 'red' }}
                                                    />
                                                  </IconButton>
                                                </Box>
                                              </Box>
                                            </Fragment>
                                          }
                                          subheaderTypographyProps={{
                                            color: '#096F4D',
                                            fontWeight: 'bold',
                                          }}
                                        />
                                      </Card>
                                    </Box>
                                  </Grid>
                                </Grid>
                              )}
                            </Draggable>
                          );
                        },
                      )}
                    </ul>
                  )}
                </Droppable>
              </DragDropContext>
            </Grid>
          ) : null}

          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-start', display: 'flex' }}
          >
            <>
              <Button
                onClick={handleClick}
                startIcon={<AddIcon />}
                sx={{
                  backgroundColor: DEFAULT_COLOR_THEME,
                  '&:hover': {
                    backgroundColor: DEFAULT_COLOR_THEME,
                  },
                  color: '#FFFFFF',
                }}
              >
                Add Prefill Question
              </Button>

              <Menu open={open} onClose={handleClose} anchorEl={anchorEl}>
                {Object.keys(prefilledQuestions).map((key: string) => {
                  return (
                    <NestedMenuItem
                      key={key}
                      label={constantStringToHumanized(key)}
                      parentMenuOpen={open}
                      onClick={handleClose}
                      rightIcon={<ChevronRightOutlinedIcon />}
                      leftIcon={prefilledQuestionIcons(key)}
                    >
                      {prefilledQuestions[key].map((question: any) => {
                        return (
                          <MenuItem
                            onClick={() =>
                              handleOnSelectPrefillQuestions(question)
                            }
                            disabled={prefillQuestions
                              .map((prefill: any) => prefill.type)
                              .includes(question.type)}
                            key={question.type}
                          >
                            {question.label}
                          </MenuItem>
                        );
                      })}
                    </NestedMenuItem>
                  );
                })}
              </Menu>
            </>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LeadFormPrefillQuestionForm;
