import {
  Box,
  Button,
  Typography,
  Drawer,
  Divider,
  Modal,
  Checkbox,
  FormControlLabel,
  Radio,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';
import { scrollBarGreen, flows } from '../../../../utils/constants/google';
import {
  MdAdd,
  MdOutlineEdit,
  MdOutlineCancel,
  MdOutlineFileCopy,
} from 'react-icons/md';
import { Brand } from '../../../../types';
import GGTemplateCreation from './GGTemplateCreation';
import PageHeader from '../../../PageHeader';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import {
  fetchGoogleTemplates,
  setGoogleTemplates,
} from '../../../../services/googleAds';
import CircularLoading from '../../../CircularLoading';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../../../redux/actions/ToggleAlert';
import GoogleTemplatesPreview from './GGLTemplatesPreview';
import GoogleTemplateSummary from './GGLTemplateSummary';
import googlesearch from '../../../../assets/images/google/google-search.png';
import googledisplay from '../../../../assets/images/google/google-display.png';

interface GGTemplatezIndexProps {
  defaultRefreshToken: string;
  brand: Brand;
  connectedAccountsIds: string[];
  campaignProvider: string;
}

const GGTemplatezIndex: React.FC<GGTemplatezIndexProps> = ({
  defaultRefreshToken,
  brand,
  connectedAccountsIds,
  campaignProvider,
}) => {
  const [templateCollection, setTemplateCollection] = useState([]);
  const [isCreatingTemplate, setIsCreatingTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedNewTemplate, setSelectedNewTemplate] = React.useState('');
  const [editModalOpen, setEditModalOpen] = React.useState(false);
  const [editPages, setEditPages] = React.useState([]);
  const [isExisting, setIsExisting] = React.useState(false);

  const dispatch = useDispatch();

  const handleCloseTemplateEdit = () => {
    setSelectedNewTemplate('');
    setEditModalOpen(false);
  };

  const handleEditTemplateContinue = () => {
    if (!selectedNewTemplate) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: 'Please select a template type to continue',
          type: 'error',
        }),
      );
      return;
    }

    const editPages =
      selectedNewTemplate === 'Search'
        ? flows.search
        : selectedNewTemplate === 'Display'
        ? flows.display
        : selectedNewTemplate === 'Performance Max'
        ? flows.performancemax
        : [];
    setEditModalOpen(false);
    setEditPages(editPages);
    //setIsExisting(true);
    setIsCreatingTemplate(true);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: `100%`,
    maxWidth: 600,
    bgcolor: 'background.paper',
    border: `4px solid ${DEFAULT_COLOR_THEME}`,
    boxShadow: 24,
    p: 4,
  };

  const getTemplates = async () => {
    setIsLoading(true);
    const ts = await fetchGoogleTemplates(brand?._id);

    if (ts.data) {
      setTemplateCollection(ts.data);
    }

    setIsLoading(false);
  };

  const checkDuplicateProductAndService = (productAndService: string) => {
    if (
      isExisting &&
      selectedTemplate.product_and_service === productAndService
    ) {
      return true;
    }
    return templateCollection
      .map((t) => t.product_and_service)
      .includes(productAndService);
  };

  const saveTempate = async (template: any) => {
    setIsLoading(true);
    const templateNames = templateCollection.map((t) => t.name);

    if (templateNames.includes(template.name) && !isExisting) {
      dispatch(
        toggleAlert({
          toggle: true,
          message: `Template with the name ${template.name} already exists`,
          type: 'error',
        }),
      );
      setIsLoading(false);
      return { error: 'Template Name already exists' };
    }

    // if (templateProductAndServices.includes(template.product_and_service)) {
    //   dispatch(
    //     toggleAlert({
    //       toggle: true,
    //       message: `Template with the product and service ${template.product_and_service} already exists`,
    //       type: 'error',
    //     }),
    //   );
    //   setIsLoading(false);
    //   return { error: 'Template Product and Service already exists' };
    // }

    const saveTp = await setGoogleTemplates({
      brandId: brand?._id,
      ...template,
    });

    if (saveTp) {
      setTemplateCollection([...templateCollection, { ...template }]);
    }

    setIsLoading(false);
    setIsCreatingTemplate(false);
    dispatch(
      toggleAlert({
        toggle: true,
        message: `Template Saved Successfully`,
      }),
    );
  };

  useEffect(() => {
    getTemplates();
  }, []);

  const PreviewDrawer = (
    <Box
      sx={{
        width: 440,
        ...scrollBarGreen,
        minHeight: '100vh',
        overflowY: 'auto',
      }}
      role="presentation"
      onClick={() => {
        // setOpen(false);
      }}
    >
      <Box
        bgcolor={`#eeeeee`}
        sx={{
          position: `sticky`,
          top: 0,
        }}
      >
        <Typography variant="h6" sx={{ padding: 2 }}>
          Template Details
        </Typography>
      </Box>
      <Divider />
      <Box paddingTop={2}>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>Name:</Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
          }}
        >
          {selectedTemplate.name}
        </Typography>
        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Product and Service:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
            textTransform: 'capitalize',
          }}
        >
          {selectedTemplate.product_and_service}
        </Typography>

        <Typography sx={{ paddingLeft: 2, fontWeight: 600 }}>
          Category:
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: DEFAULT_COLOR_THEME,
            paddingLeft: 4,
            marginBottom: 2,
            textTransform: 'capitalize',
          }}
        >
          {selectedTemplate.category}
        </Typography>

        <Divider />

        <GoogleTemplatesPreview
          category={selectedTemplate?.category}
          headlines={selectedTemplate?.headlines}
          descriptions={selectedTemplate?.descriptions}
          sitelinks={selectedTemplate?.sitelinks}
          url={selectedTemplate?.final_url}
          showToolbar={false}
          images={selectedTemplate?.images}
          business_logo={selectedTemplate?.business_logo}
          business_name={selectedTemplate?.business_name || ''}
        />
      </Box>

      <Divider />
      <Box
        sx={{
          minHeight: '75svh',
        }}
      >
        {Object.keys(selectedTemplate).length > 0 ? (
          <Box sx={{ paddingLeft: 2, paddingTop: 2 }}>
            <GoogleTemplateSummary template={selectedTemplate} />
          </Box>
        ) : null}
      </Box>
      <Divider />
      <Box
        sx={{
          position: 'sticky',
          bottom: 0,
          backgroundColor: `#eeeeee`,
          display: `flex`,
          justifyContent: `space-between`,
          paddingX: 1,
          paddingY: 2,
          alignItems: `center`,
          width: `100%`,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: 0,
            paddingX: 2,
            height: 30,
            display: 'flex',
            gap: 1,
            width: `fit-content`,
          }}
          onClick={() => {
            console.log(`selectedTemplate.category`, selectedTemplate.category);
            console.log(
              `flows[selectedTemplate.category]`,
              flows[selectedTemplate.category],
            );
            setSelectedNewTemplate(selectedTemplate.category);
            setIsCreatingTemplate(true);
            setIsExisting(true);
            setEditPages([flows[selectedTemplate.category]]);
          }}
        >
          <MdOutlineEdit size={20} />
          Edit
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            paddingY: 0,
            paddingX: 2,
            height: 30,
            display: 'flex',
            gap: 1,
            width: `fit-content`,
          }}
          onClick={() => {
            console.log(`selectedTemplate.category`, selectedTemplate.category);
            console.log(
              `flows[selectedTemplate.category]`,
              flows[selectedTemplate.category],
            );
            setSelectedNewTemplate(selectedTemplate.category);
            setIsCreatingTemplate(true);
            //setIsExisting(true);
            setEditPages([flows[selectedTemplate.category]]);
          }}
        >
          <MdOutlineFileCopy size={20} />
          Duplicate
        </Button>
      </Box>
    </Box>
  );

  // const EditModal = (
  //   <Modal
  //     open={editModalOpen}
  //     // onClose={handleCloseConfirmOverwrite}
  //     aria-labelledby="modal-modal-title"
  //     aria-describedby="modal-modal-description"
  //   >
  //     <Box sx={modalStyle}>
  //       <Typography
  //         id="modal-modal-title"
  //         variant="h6"
  //         component="h2"
  //         color={DEFAULT_COLOR_THEME}
  //         sx={{
  //           display: `flex`,
  //           justifyContent: `start`,
  //           gap: 1,
  //           alignItems: `center`,
  //           fontWeight: 600,
  //         }}
  //       >
  //         New Google Ads Template
  //       </Typography>

  //       <Typography id="modal-modal-description" sx={{ mt: 2 }}>
  //         Please select a template type to create:
  //       </Typography>
  //       <Box sx={{ marginBottom: 2, paddingLeft: `20px` }}>
  //         <Box>
  //           <FormControlLabel
  //             control={
  //               <Radio
  //                 checked={selectedNewTemplate === 'Search'}
  //                 onChange={() => {
  //                   setSelectedNewTemplate('Search');
  //                 }}
  //               />
  //             }
  //             label={
  //               <Box
  //                 my={2}
  //                 sx={{
  //                   padding: 2,
  //                   borderRadius: 2,
  //                   border:
  //                     selectedNewTemplate === 'Search'
  //                       ? `3px solid ${DEFAULT_COLOR_THEME}`
  //                       : `3px solid transparent`,
  //                 }}
  //               >
  //                 <Typography variant="body1">Responsive Search Ad</Typography>
  //                 <img src={googlesearch} alt="googlesearch" />
  //               </Box>
  //             }
  //           />
  //         </Box>

  //         <Box>
  //           <FormControlLabel
  //             control={
  //               <Radio
  //                 disabled
  //                 checked={selectedNewTemplate === 'Display'}
  //                 onChange={() => {
  //                   setSelectedNewTemplate('Display');
  //                 }}
  //               />
  //             }
  //             label={
  //               <Box
  //                 my={2}
  //                 sx={{
  //                   padding: 2,
  //                   borderRadius: 2,
  //                   border:
  //                     selectedNewTemplate === 'Display'
  //                       ? `3px solid ${DEFAULT_COLOR_THEME}`
  //                       : `3px solid transparent`,
  //                 }}
  //               >
  //                 <Typography variant="body1">
  //                   Display Ad (Coming Soon)
  //                 </Typography>
  //                 <img src={googledisplay} alt="googledisplay" />
  //               </Box>
  //             }
  //           />
  //         </Box>
  //       </Box>

  //       <Box
  //         sx={{
  //           display: `flex`,
  //           justifyContent: `space-between`,
  //           gap: 2,
  //         }}
  //       >
  //         <Button onClick={handleCloseTemplateEdit}>Cancel</Button>
  //         <Button variant="contained" onClick={handleEditTemplateContinue}>
  //           Continue
  //         </Button>
  //       </Box>
  //     </Box>
  //   </Modal>
  // );

  return (
    <Box sx={{ paddingTop: 4, paddingX: 4 }}>
      <PageHeader title="Google Ads Templatez" />
      <CircularLoading loading={isLoading} />
      {!isCreatingTemplate ? (
        <>
          <Box
            sx={{
              display: `flex`,
              justifyContent: `flex-end`,
              mb: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ paddingY: 0, paddingX: 2, height: 30 }}
              onClick={() => {
                //setSelectedNewTemplate('');
                //setEditModalOpen(true);
                setIsCreatingTemplate(true);
                setEditPages(['Selection']);
                setSelectedNewTemplate('');
              }}
            >
              Create New Template
            </Button>
          </Box>
          <Box>
            {templateCollection.length > 0 ? (
              <Box
                sx={{
                  '& .green-header': {
                    backgroundColor: DEFAULT_COLOR_THEME,
                    color: 'white',
                    fontWeight: 'bold',
                  },
                }}
              >
                <DataGridPremium
                  rows={templateCollection.map((template, index) => ({
                    id: index,
                    ...template,
                  }))}
                  onRowClick={(row) => {
                    setSelectedTemplate(row.row);
                    setOpen(true);
                  }}
                  columns={[
                    {
                      field: 'name',
                      headerName: 'Template Name',
                      headerClassName: 'green-header',
                      width: 300,
                      flex: 1,
                    },
                    {
                      field: 'product_and_service',
                      headerName: 'Product and Service',
                      headerClassName: 'green-header',
                      width: 300,
                    },
                    {
                      field: 'category',
                      headerName: 'Category',
                      width: 300,
                      headerClassName: 'green-header',
                    },
                  ]}
                  hideFooter
                />
                <Drawer
                  anchor="right"
                  open={open}
                  onClose={() => {
                    setOpen(false);
                  }}
                >
                  {PreviewDrawer}
                </Drawer>
              </Box>
            ) : (
              <Box
                sx={{
                  width: '100%',
                  minHeight: '200px',
                  border: `2px solid ${DEFAULT_COLOR_THEME}`,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mb: 3,
                }}
              >
                <Box sx={{ maxWidth: `500px`, textAlign: `center` }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: DEFAULT_COLOR_THEME,
                      fontWeight: 'bold',
                    }}
                  >
                    No Templates Found
                  </Typography>
                  Please create a New Template by Clicking the Button on the
                  upper right corner
                </Box>
              </Box>
            )}
          </Box>
        </>
      ) : null}
      {isCreatingTemplate ? (
        <>
          <Box
            sx={{
              textAlign: `center`,
              position: `relative`,
              mb: 2,
            }}
          >
            <Box
              sx={{
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }}
            >
              {isExisting
                ? 'Edit Template'
                : `Creating New ${selectedNewTemplate} Template`}
              :{` `}
              {isExisting ? selectedTemplate?.name : ''}
            </Box>
            <Button
              variant="contained"
              color="primary"
              sx={{
                display: `none`,
                paddingY: 0,
                paddingX: 2,
                height: 30,
                position: 'absolute',
                right: 0,
                top: 0,
              }}
              onClick={() => {
                setIsCreatingTemplate(false);
              }}
            >
              Cancel
            </Button>
          </Box>

          <GGTemplateCreation
            checkPS={checkDuplicateProductAndService}
            creationComplete={saveTempate}
            brandId={brand?._id}
            defaultRefreshToken={defaultRefreshToken}
            isExisting={isExisting}
            template={selectedTemplate}
            editPages={editPages}
            cancelCreation={() => {
              setIsCreatingTemplate(false);
              setIsExisting(false);
            }}
            selectedTemplateType={(type) => {
              //make type propercase
              console.log(`🚀 type`, type);
              const labels: any = {
                performancemax: 'Performance Max',
                search: 'Search',
                display: 'Display',
              };
              console.log(`🚀 newtype`, labels[type]);
              setSelectedNewTemplate(labels[type]);
            }}
          />
        </>
      ) : null}
    </Box>
  );
};

export default GGTemplatezIndex;
