import React, { SyntheticEvent, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Reports.module.scss';
import PageHeader from '../../components/PageHeader';
import { Brand } from '../../types';
import { useSelector } from 'react-redux';
import { CrmSetting } from '../../types/ICrm';
import { getCrmSettingsByBrand } from '../../services/crm';
import { CrmProvider, DEFAULT_COLOR_THEME } from '../../utils';
import ClientTetherReport from './ClientTetherReport';
import CircularLoading from '../../components/CircularLoading';
import { NoData } from '../NoContent';
import HubSpotReport from './HubSpotReport';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { humanizeString } from '../../utils/stringModifier';
import { Tab } from '@mui/material';

const Pipelinez: React.FC = () => {
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const campaignProvider = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const [crmSettings, setCrmSettings] = useState<CrmSetting>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState<string>('1');

  useEffect(() => {
    if (location || brand) getCrmSettings();
  }, [location, brand]);

  const getCrmSettings = async () => {
    try {
      setFetchLoading(true);
      const response = await getCrmSettingsByBrand((location || brand)?._id);

      setCrmSettings(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const handleChangeTabValue = (_: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const singlePipelineReport = () => {
    if (crmSettings?.providers?.includes(CrmProvider.CLIENT_TETHER)) {
      return (
        <ClientTetherReport
          crmSettings={crmSettings}
          brand={location || brand}
          provider={campaignProvider}
        />
      );
    }

    if (crmSettings?.providers?.includes(CrmProvider.HUBSPOT)) {
      return (
        <HubSpotReport
          crmSettings={crmSettings}
          brand={location || brand}
          provider={campaignProvider}
        />
      );
    }

    return <NoData />;
  };

  const buildTabs = (providers: string[]) => {
    return providers.map((provider: string) =>
      humanizeString(provider.split('-').join(' ')),
    );
  };

  const renderTabContent = (tab: string) => {
    if (tab === 'Client Tether') {
      return (
        <ClientTetherReport
          crmSettings={crmSettings}
          brand={location || brand}
          provider={campaignProvider}
        />
      );
    }

    if (tab === 'Hubspot') {
      return (
        <HubSpotReport
          crmSettings={crmSettings}
          brand={location || brand}
          provider={campaignProvider}
        />
      );
    }
  };

  return (
    <div className={styles.report}>
      <CircularLoading loading={fetchLoading} />
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader title="Pipelinez" />
        </div>

        {crmSettings?.providers?.length > 1 ? (
          <TabContext value={tabValue}>
            <TabList
              onChange={handleChangeTabValue}
              variant="scrollable"
              allowScrollButtonsMobile
              scrollButtons
              textColor="secondary"
              TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
              TabScrollButtonProps={{
                style: { backgroundColor: DEFAULT_COLOR_THEME, color: 'white' },
              }}
              sx={{
                backgroundColor: DEFAULT_COLOR_THEME,
                '.MuiTab-root': {
                  top: 5,
                  color: 'white',
                },
                '.Mui-selected': {
                  color: `${DEFAULT_COLOR_THEME} !important`,
                  backgroundColor: 'white',
                  top: 5,
                  borderRadius: 2,
                },
                width: '100%',
              }}
            >
              {buildTabs(crmSettings?.providers || [])?.map(
                (tab: string, index: number) => (
                  <Tab label={tab} value={`${index + 1}`} key={`crm-${tab}`} />
                ),
              )}
            </TabList>

            {buildTabs(crmSettings?.providers || [])?.map(
              (tab: string, index: number) => (
                <TabPanel
                  value={`${index + 1}`}
                  sx={{
                    backgroundColor: '#d9d9d933',
                    minHeight: `calc(100vh - 260px)`,
                    maxHeight: `calc(100vh - 260px)`,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    '&::-webkit-scrollbar': {
                      width: '10px',
                    },
                    '&::-webkit-scrollbar-track': {
                      borderTopRightRadius: '5px',
                      borderBottomRightRadius: '5px',
                    },

                    '&::-webkit-scrollbar-thumb': {
                      background: DEFAULT_COLOR_THEME,
                      borderRadius: '18px',

                      '&:hover': {
                        background: DEFAULT_COLOR_THEME,
                      },
                    },
                  }}
                  key={`panel-${tab}`}
                >
                  {renderTabContent(tab)}
                </TabPanel>
              ),
            )}
          </TabContext>
        ) : crmSettings?.providers?.length === 1 ? (
          singlePipelineReport()
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default Pipelinez;
