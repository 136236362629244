import React, { Fragment, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Pages.module.scss';
import PageHeader from '../../components/PageHeader';
import { Brand } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getAlerts, updateAlert } from '../../services/alerts';
import { Alert } from '../../types/IAlert';
import BarChart from '../../components/Charts/BarChart';
import { humanizeString } from '../../utils/stringModifier';
import { AIADZ_GREEN_HEX, AIADZ_YELLOW_HEX, RED_HEX } from '../../utils';
import { NoData } from '../NoContent';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
} from '@mui/material';
import CircularLoading from '../../components/CircularLoading';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import {
  setBrand,
  setBrands,
  setLocation,
  setLocations,
  toggleAlert,
} from '../../redux/actions';
import { PrimaryButton } from '../../components/Buttons';
import { dayJsInstance } from '../../utils/dayjsHelper';
import { orderArrayOfObject } from '../../utils/arrayFormatter';

const Alertz: React.FC = () => {
  const dispatch = useDispatch();
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const brands: Brand[] = useSelector((state: any) => state?.brands?.brands);
  const locations: Brand[] = useSelector(
    (state: any) => state?.locations?.locations,
  );
  const timezone =
    useSelector((state: any) => state?.brandTimezone?.timezone) || 'Etc/UTC';

  const [activeAlerts, setActiveAlerts] = useState<Alert[]>([]);
  const [alertHistory, setAlertHistory] = useState<Alert[]>([]);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [showPreviousAlertz, setShowPreviousAlertz] = useState<boolean>(false);

  useEffect(() => {
    if ((location || brand)?._id) {
      fetchAllAlerts();
    }
  }, [brand?._id, location?._id]);

  const fetchAllAlerts = async () => {
    try {
      setFetchLoading(true);
      const response = await getAlerts((location || brand)?._id);

      const active = response.data.filter((alert: Alert) => !alert.archived);

      const inactiveAlerts = response.data.filter(
        (alert: Alert) => alert.archived,
      );

      setActiveAlerts(active);
      setAlertHistory(inactiveAlerts);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const archiveAlert = async (alert: Alert) => {
    try {
      setLoading(true);
      const response = await updateAlert(alert._id, alert);

      let tempAlerts: Alert[] = [];
      activeAlerts.forEach((activeAlert: Alert) => {
        if (activeAlert._id === response.data._id) {
          if (location) {
            const alertsCount = response.data.archived
              ? location?.activeAlertsCount - 1
              : location?.activeAlertsCount + 1;

            let updatedLocations: Brand[] = [];
            locations.forEach((location: Brand) => {
              if (location?._id === response.data.brand) {
                location.activeAlertsCount = alertsCount;
              }

              updatedLocations = [...updatedLocations, brand];
            });

            dispatch(setLocations(updatedLocations));
            dispatch(
              setLocation({
                ...location,
                activeAlertsCount: alertsCount,
              }),
            );
          } else {
            const alertsCount = response.data.archived
              ? brand?.activeAlertsCount - 1
              : brand?.activeAlertsCount + 1;

            let updatedBrands: Brand[] = [];
            brands.forEach((brand: Brand) => {
              if (brand?._id === response.data.brand) {
                brand.activeAlertsCount = alertsCount;
              }

              updatedBrands = [...updatedBrands, brand];
            });

            dispatch(setBrands(updatedBrands));
            dispatch(
              setBrand({
                ...brand,
                activeAlertsCount: alertsCount,
              }),
            );
          }

          activeAlert = response.data;
        }

        tempAlerts = [...tempAlerts, activeAlert];
      });

      setActiveAlerts(tempAlerts);
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setLoading(false);
    }
  };

  const renderChart = (alert: Alert) => {
    if (alert.category === 'cpm_increase') {
      return (
        <BarChart
          chartData={{
            labels: ['CPM (Cost per Impressions)'],
            datasets: [
              {
                label: 'Previous Week CPM',
                data: [
                  alert.data.impressions.previousWeek > 0
                    ? alert.data.previousWeekSpend /
                      alert.data.impressions.previousWeek
                    : 0,
                ],
                backgroundColor: AIADZ_YELLOW_HEX,
              },
              {
                label: 'Current Week CPM',
                data: [
                  alert.data.impressions.currentWeek > 0
                    ? alert.data.currentWeekSpend /
                      alert.data.impressions.currentWeek
                    : 0,
                ],
                backgroundColor: RED_HEX,
              },
            ],
          }}
          title={[`${humanizeString(alert.type)} Alert - CPM`]}
          subtitle={[
            `${humanizeString(alert.level)}: ${alert.adsetName}`,
            `${alert.alertDescription}`,
            `Previous: ${dayJsInstance
              .unix(alert.data.previousWeekStart)
              .tz(timezone)
              .format('MM/DD/YYYY')} - ${dayJsInstance
              .unix(alert.data.previousWeekEnd)
              .tz(timezone)
              .format('MM-DD-YYYY')}  Recent: ${dayJsInstance
              .unix(alert.data.currentWeekStart)
              .tz(timezone)
              .format('MM/DD/YYYY')} - ${dayJsInstance
              .unix(alert.data.currentWeekEnd)
              .tz(timezone)
              .format('MM/DD/YYYY')}`,
          ]}
          isCurrency
          titleFontSize={20}
          subtitleFontSize={14}
        />
      );
    }

    if (alert.category === 'cpl_increase') {
      return (
        <BarChart
          chartData={{
            labels: ['CPL (Cost per Lead)'],
            datasets: [
              {
                label: 'Previous Week CPL',
                data: [
                  alert.data.leads.previousWeek > 0
                    ? alert.data.previousWeekSpend /
                      alert.data.leads.previousWeek
                    : 0,
                ],
                backgroundColor: AIADZ_YELLOW_HEX,
              },
              {
                label: 'Current Week CPL',
                data: [
                  alert.data.leads.currentWeek > 0
                    ? alert.data.currentWeekSpend / alert.data.leads.currentWeek
                    : 0,
                ],
                backgroundColor: RED_HEX,
              },
            ],
          }}
          title={[`${humanizeString(alert.type)} Alert - CPL`]}
          subtitle={[
            `${humanizeString(alert.level)}: ${alert.adsetName}`,
            alert.alertDescription,
            `Previous: ${dayJsInstance
              .unix(alert.data.previousWeekStart)
              .tz(timezone)
              .format('MM/DD/YYYY')} - ${dayJsInstance
              .unix(alert.data.previousWeekEnd)
              .tz(timezone)
              .format('MM-DD-YYYY')}  Recent: ${dayJsInstance
              .unix(alert.data.currentWeekStart)
              .tz(timezone)
              .format('MM/DD/YYYY')} - ${dayJsInstance
              .unix(alert.data.currentWeekEnd)
              .tz(timezone)
              .format('MM/DD/YYYY')}`,
          ]}
          isCurrency
          titleFontSize={20}
          subtitleFontSize={14}
        />
      );
    }

    if (alert.category === 'cpql_increase') {
      return (
        <BarChart
          chartData={{
            labels: ['CPQL (Cost per Qualified Lead'],
            datasets: [
              {
                label: 'Previous Week CPQL',
                data: [
                  alert.data.qualifiedLeads.previousWeek > 0
                    ? alert.data.previousWeekSpend /
                      alert.data.qualifiedLeads.previousWeek
                    : 0,
                ],
                backgroundColor: AIADZ_YELLOW_HEX,
              },
              {
                label: 'Current Week CPQL',
                data: [
                  alert.data.qualifiedLeads.currentWeek > 0
                    ? alert.data.currentWeekSpend /
                      alert.data.qualifiedLeads.currentWeek
                    : 0,
                ],
                backgroundColor: RED_HEX,
              },
            ],
          }}
          title={[`${humanizeString(alert.type)} Alert - CPQL`]}
          subtitle={[
            `${humanizeString(alert.level)}: ${alert.adsetName}`,
            alert.alertDescription,
            `Previous: ${dayJsInstance
              .unix(alert.data.previousWeekStart)
              .tz(timezone)
              .format('MM/DD/YYYY')} - ${dayJsInstance
              .unix(alert.data.previousWeekEnd)
              .tz(timezone)
              .format('MM-DD-YYYY')}  Recent: ${dayJsInstance
              .unix(alert.data.currentWeekStart)
              .tz(timezone)
              .format('MM/DD/YYYY')} - ${dayJsInstance
              .unix(alert.data.currentWeekEnd)
              .tz(timezone)
              .format('MM/DD/YYYY')}`,
          ]}
          isCurrency
          titleFontSize={20}
          subtitleFontSize={14}
        />
      );
    }

    if (alert.category === 'qualified_leads') {
      return (
        <BarChart
          chartData={{
            labels: ['Leads'],
            datasets: [
              {
                label: 'Leads',
                data: [alert.data.leads.currentMonth || 0],
                backgroundColor: AIADZ_YELLOW_HEX,
              },
              {
                label: 'Qualified Leads',
                data: [alert.data.qualifiedLeads.currentMonth || 0],
                backgroundColor:
                  alert.type === 'green' ? AIADZ_GREEN_HEX : RED_HEX,
              },
            ],
          }}
          title={[`${humanizeString(alert.type)} Alert - Qualified Leads`]}
          subtitle={[
            `${humanizeString(alert.level)}: ${alert.adsetName}`,
            alert.alertDescription,
            `Duration: ${dayJsInstance
              .unix(alert.data.currentMonthStart)
              .tz(timezone)
              .format('MM-DD-YYYY')} - ${dayJsInstance
              .unix(alert.data.currentMonthEnd)
              .tz(timezone)
              .format('MM-DD-YYYY')}`,
          ]}
          titleFontSize={20}
          subtitleFontSize={14}
        />
      );
    }

    if (alert.category === 'underperforming_ad') {
      return (
        <BarChart
          chartData={{
            labels: ['Best and Worst Performing Ad'],
            datasets: [
              {
                label: `Best Performing Ad CPL (${alert.data.performance.bestPerforming.name})`,
                data: [alert.data.performance.bestPerforming.cpl || 0],
                backgroundColor: AIADZ_YELLOW_HEX,
              },
              {
                label: `Worst Performing Ad CPL (${alert.data.performance.worstPerforming.name})`,
                data: [alert.data.performance.worstPerforming.cpl || 0],
                backgroundColor: RED_HEX,
              },
            ],
          }}
          title={[`${humanizeString(alert.type)} Alert - Poor Performing Ad`]}
          subtitle={[
            `${humanizeString(alert.level)}: ${alert.adName}`,
            alert.alertDescription,
            `Duration: ${dayJsInstance
              .unix(alert.data.durationStartDate)
              .tz(timezone)
              .format('MM-DD-YYYY')} - ${dayJsInstance
              .unix(alert.data.durationEndDate)
              .tz(timezone)
              .format('MM-DD-YYYY')}`,
          ]}
          isCurrency
          titleFontSize={20}
          subtitleFontSize={14}
        />
      );
    }

    if (alert.category === 'underperforming_audience') {
      return (
        <BarChart
          chartData={{
            labels: ['Best and Worst Performing Audience'],
            datasets: [
              {
                label: `Best Performing Audience CPL (${alert.data.performance.bestPerforming.name})`,
                data: [alert.data.performance.bestPerforming.cpl || 0],
                backgroundColor: AIADZ_YELLOW_HEX,
              },
              {
                label: `Worst Performing Audience CPL (${alert.data.performance.worstPerforming.name})`,
                data: [alert.data.performance.worstPerforming.cpl || 0],
                backgroundColor: RED_HEX,
              },
            ],
          }}
          title={[
            `${humanizeString(alert.type)} Alert - Poor Performing Audience`,
          ]}
          subtitle={[
            `${humanizeString(alert.level)}: ${alert.adsetName}`,
            alert.alertDescription,
            `Duration: ${dayJsInstance
              .unix(alert.data.durationStartDate)
              .tz(timezone)
              .format('MM-DD-YYYY')} - ${dayJsInstance
              .unix(alert.data.durationEndDate)
              .tz(timezone)
              .format('MM-DD-YYYY')}`,
          ]}
          isCurrency
          titleFontSize={20}
          subtitleFontSize={14}
        />
      );
    }

    if (alert.category === 'zero_leads') {
      const sortedData = orderArrayOfObject(alert.data.leads.value, 'date');
      const data = sortedData.map((data: any) => {
        return data.leads;
      });

      const labels = sortedData.map((data: any) => {
        return `${dayJsInstance
          .unix(data.date)
          .tz(timezone)
          .format('MM-DD-YYYY')}`;
      });

      return (
        <BarChart
          chartData={{
            labels,
            datasets: [
              {
                label: `Leads`,
                data,
                backgroundColor: AIADZ_YELLOW_HEX,
              },
            ],
          }}
          title={[`${humanizeString(alert.type)} Alert - Zero Leads`]}
          subtitle={[
            alert.alertDescription,
            `Duration: ${dayJsInstance
              .unix(alert.data.durationStartDate)
              .tz(timezone)
              .format('MM-DD-YYYY')} - ${dayJsInstance
              .unix(alert.data.durationEndDate)
              .tz(timezone)
              .format('MM-DD-YYYY')}`,
          ]}
          titleFontSize={20}
          subtitleFontSize={14}
        />
      );
    }

    return <></>;
  };

  return (
    <div className={styles.page}>
      <CircularLoading loading={fetchLoading} />

      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader
            title={`${showPreviousAlertz ? 'Previous' : 'Active'} Alertz`}
          />

          <div className={styles.controls}>
            <div className={styles.buttons}>
              {alertHistory.length > 0 ? (
                <PrimaryButton
                  title={
                    showPreviousAlertz
                      ? 'View Active Alertz'
                      : 'View Previous Alertz'
                  }
                  loading={loading}
                  type="button"
                  handleOnClick={() => setShowPreviousAlertz((prev) => !prev)}
                />
              ) : null}
            </div>
          </div>
        </div>

        {(showPreviousAlertz ? alertHistory : activeAlerts).length > 0 ? (
          (showPreviousAlertz ? alertHistory : activeAlerts).map(
            (activeAlert: Alert) => {
              return (
                <Card key={activeAlert._id} sx={{ padding: '20px', mb: 2 }}>
                  <CardHeader
                    subheader={
                      <Fragment>
                        <Box sx={{ flexDirection: 'column' }}>
                          <Typography variant="body2">{`Reported: ${dayJsInstance(
                            activeAlert.createdAt,
                          )
                            .tz(timezone)
                            .format('LL')}`}</Typography>

                          {showPreviousAlertz ? (
                            <Typography variant="body2">{`Archived: ${dayJsInstance
                              .unix(activeAlert.archivedAt)
                              .tz(timezone)
                              .format('LL')}`}</Typography>
                          ) : null}
                        </Box>
                      </Fragment>
                    }
                    sx={{ paddingTop: 0 }}
                  />

                  <CardContent>{renderChart(activeAlert)}</CardContent>

                  {!showPreviousAlertz ? (
                    <CardActions>
                      {!loading ? (
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              defaultChecked={activeAlert.archived}
                            />
                          }
                          name="archived"
                          label="Archive Alert"
                          value={activeAlert.archived}
                          onChange={(e: any) =>
                            archiveAlert({
                              ...activeAlert,
                              archived: e.target.checked,
                            })
                          }
                        />
                      ) : (
                        <CircularProgress size={25} />
                      )}
                    </CardActions>
                  ) : null}
                </Card>
              );
            },
          )
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default Alertz;
