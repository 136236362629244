import { diyAdzUrl } from '../services/api';
import { Objectives } from './constants/facebookAds';

const baseUrl = window.location.host;

export const LOGGED_IN_USER: string = 'LOGGED_IN_USER';
export const ADMIN: string = 'admin';
export const SUPER_ADMIN: string = 'super-admin';
export const SALESPERSON: string = 'salesperson';
export const AGENCY: string = 'agency';
export const BRAND_MANAGER: string = 'brand-manager';
export const MARKETING_MANAGER: string = 'marketing-manager';
export const GET_THEME: string = 'GET_THEME';
export const CREATE_THEME: string = 'CREATE_THEME';
export const EDIT_THEME: string = 'EDIT_THEME';
export const AUTH_TOKEN: string = 'auth_token';
export const BRAND_SESSION: string = 'brand_session';
export const BRAND_FILTER_SESSION: string = 'brand_filter_session';
export const CAMPAIGN_PROVIDER_SESSION: string = 'campaign_provider_session';
export const BUDGET_PROVIDER_SESSION: string = 'budget_provider_session';
export const AGENCY_SESSION: string = 'agency_session';
export const LOCATION_SESSION: string = 'location_session';
export const PROFILE_SCOPES = 'email,public_profile';
export const PAGE_MANAGEMENT_SCOPES =
  'pages_messaging,pages_manage_metadata,page_events,pages_show_list,pages_manage_ads,pages_read_engagement';
export const ADS_MANAGEMENT_SCOPES = 'ads_read,ads_management';
export const BUSINESS_MANAGEMENT_SCOPES = 'business_management';
export const USER_MANAGEMENT_SCOPES = 'leads_retrieval';
export const SET_PAGE: string = 'SET_PAGE';
export const CANNED_RESPONSE_FIELDS = [
  '<<lead>>',
  '<<salesperson>>',
  '<<brand>>',
  '<<calendly_link>>',
];
export const CUSTOM_FIELDS = ['address', 'phone_number', 'custom'];
export const CUSTOM_FIELD_REGEX = /\{\{(.*?)\}\}/g;
export const IS_MESSENGER_ADS = [
  Objectives.OUTCOME_ENGAGEMENT,
  Objectives.MESSAGES,
];
export const IS_LEAD_ADS = [
  Objectives.OUTCOME_LEADS,
  Objectives.LEAD_GENERATION,
];
export const IS_TRAFFIC_ADS = [
  Objectives.OUTCOME_TRAFFIC,
  Objectives.LINK_CLICKS,
];
export const IS_SALES_ADS = [Objectives.OUTCOME_SALES];
export const GOOGLE_LOGIN_SCOPES: string =
  'email profile https://www.googleapis.com/auth/adwords openid https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
export const IS_DEVELOPMENT =
  process.env.REACT_APP_ENVIRONMENT === 'development';
export const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production';
export const IS_STAGING = process.env.REACT_APP_ENVIRONMENT === 'staging';
export const IS_DIY_ADZ = baseUrl === diyAdzUrl;
// Default lat and lng for Google Maps
// Pointed to the center of USA
export const DEFAULT_LAT: number = 39.8097343;
export const DEFAULT_LNG: number = -98.5556199;
export const TFM_TAG: string = 'TFM';
export const DEFAULT_TAG: string = 'aiAdz';
export const TIMEZONE_EASTERN = 'America/New_York';
export const TIMEZONE_CENTRAL = 'America/Chicago';
/**
 * Temporary hardcoded qualified terms database
 * TODO: Remove once dynamic qualified terms database were implemented
 */
export const QUALIFIED_SHORT_ANSWER_TERMS =
  'acqui,air force,army,buis,busi,buss,buy,capit,ceo,chain,chief,chief,comap,company,compn,conractor,contracter,contractor,convein,convenience store,convinie,dealership,entrepre,equity,excec,exec,fast casual,fianan,fianc,financ,fouder,found,franchi,fransh,fransi,fund,gas station,healthcare,hospitality,hotel,industry,industy,indutry,invest,marine,milit,miltary,money,motel,mottel,navy,open,operat,oportun,opportunity,oppurt,own,partener,partnar,partner,presedent,presidant,president,property,propet,proprietor,proprt,qsr,real estate,realit,realt,resta,restur,retail,sale,self employed,selfempl,start,venture,veteran,vetern,vetran';
export const CrmProvider = {
  CLIENT_TETHER: 'client-tether',
  HUBSPOT: 'hubspot',
};
export const CLIENT_TETHER_PROPERTIES = [
  { label: 'First Name', name: 'firstName' },
  { label: 'Last Name', name: 'lastName' },
  { label: 'Company Name', name: 'compName' },
  { label: 'Job Title', name: 'job_title' },
  { label: 'Address', name: 'address' },
  { label: 'City', name: 'city' },
  { label: 'State', name: 'state' },
  { label: 'Zip', name: 'zip' },
  { label: 'Email', name: 'email' },
  { label: 'Phone', name: 'phone' },
  { label: 'Second Phone', name: 'second_phone' },
  { label: 'Whiteboard', name: 'whiteboard' },
  { label: 'Deal Size', name: 'deal_size' },
  { label: 'High Net Worth', name: 'high_net_worth' },
  { label: 'Home Owner Status', name: 'home_owner_status' },
  { label: 'Household Income', name: 'household_income' },
  { label: 'Length Of Residence', name: 'length_of_residence' },
  { label: 'Marital Status', name: 'marital_status' },
  { label: 'Market Value', name: 'market_value' },
  { label: 'Occupation', name: 'occupation' },
  { label: 'Age', name: 'age' },
  { label: 'Education', name: 'education' },
  { label: 'Gender', name: 'gender' },
  { label: 'Anniversary 1', name: 'anniversary_1' },
  { label: 'Anniversary 2', name: 'anniversary_2' },
  { label: 'Facebook', name: 'facebook' },
  { label: 'LinkedIn', name: 'linkedIn' },
  { label: 'Twitter', name: 'twitter' },
  { label: 'Tags', name: 'tags' },
];
export const CLIENT_TETHER_DEFAULT_MAP = [
  { crmField: 'firstName', formField: 'first_name' },
  { crmField: 'lastName', formField: 'last_name' },
  { crmField: 'compName', formField: 'company_name' },
  { crmField: 'job_title', formField: 'job_title' },
  { crmField: 'address', formField: 'street_address' },
  { crmField: 'city', formField: 'city' },
  { crmField: 'state', formField: 'state' },
  { crmField: 'zip', formField: 'zip' },
  { crmField: 'phone', formField: 'phone' },
  { crmField: 'marital_status', formField: 'maritial_status' },
  { crmField: 'email', formField: 'email' },
  { crmField: 'gender', formField: 'gender' },
];
export const HUBSPOT_DEFAULT_MAP = [
  { crmField: 'firstname', formField: 'first_name' },
  { crmField: 'lastname', formField: 'last_name' },
  { crmField: 'company', formField: 'company_name' },
  { crmField: 'jobtitle', formField: 'job_title' },
  { crmField: 'address', formField: 'street_address' },
  { crmField: 'city', formField: 'city' },
  { crmField: 'state', formField: 'state' },
  { crmField: 'zip', formField: 'zip' },
  { crmField: 'phone', formField: 'phone' },
  { crmField: 'marital_status', formField: 'maritial_status' },
  { crmField: 'email', formField: 'email' },
  { crmField: 'gender', formField: 'gender' },
  { crmField: 'relationship_status', formField: 'relationship_status' },
];
